import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import PropTypes from 'prop-types';

import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
} from '@mui/material';
import _uniqueId from 'lodash/uniqueId';

import { TableSkeleton, TableHeadCustom, TableEmptyRows, TableNoData, } from '../../../components/table';
import Scrollbar from '../../../components/Scrollbar';
import IMStockMovementsTableToolbar from './IMStockMovementsTableToolbar';
import TableRowCellsFromHeader from '../../../components/table/TableRowCellsFromHeader';
import TableFooter from '../../../components/table/TableFooter';
import IMTableToolbar from './IMTableToolbar';

import useTable, { emptyRows, getComparator } from '../../../hooks/useTable';
import useLocales from '../../../hooks/useLocales';

import { applySortFilter, numberByLocale } from '../../../utils/misc';
import { fDateUnixUTC, fDateHoursUTC } from '../../../utils/formatTime';

import { selectIMStockMovementsFilters, setFilters } from '../../../redux/slices/imStockMovementsSlice';

import { PATH_DASHBOARD } from '../../../routes/paths';

IMStockRecentMovementsTable.propTypes = {
    isLoading: PropTypes.bool,
    tableDataList: PropTypes.array.isRequired,
    isModal: PropTypes.bool,
}

const TABLE_HEAD_LIST = [
    { id: 'date', label: 'day', sortable: true, show: true, align: 'left' },
    { id: 'hour', label: 'hour', sortable: true, show: true, align: 'left' },
    { id: 'articleName', label: 'item', sortable: true, show: true, align: 'left' },
    { id: 'operationTypeName', label: 'operation', sortable: true, show: true, align: 'center' },
    { id: 'originStockName', label: 'sourceStock', sortable: true, show: true, align: 'center' },
    { id: 'destinyStockName', label: 'destinyStock', sortable: true, show: true, align: 'center' },
    { id: 'tankName', label: 'tank', sortable: true, show: true, align: 'center' },
    { id: 'consumedTotalQuantity', label: 'affectedQuantity', sortable: true, show: true, align: 'center' },
    { id: 'currentTotalQuantity', label: 'initialQuantity', sortable: true, show: true, align: 'center' },
    { id: 'finalTotalQuantity', label: 'finalQuantity', sortable: true, show: true, align: 'center' },
    { id: 'supervisorFullName', label: 'doneBy', sortable: true, show: true, align: 'center' },
    { id: 'notes', label: 'notes', sortable: true, show: true, align: 'center' },
];

export default function IMStockRecentMovementsTable({ isLoading, tableDataList, isModal }) {
    const dispatch = useDispatch();
    const { translate, currentLang } = useLocales();
    const isStocksPage = useMatch(PATH_DASHBOARD.inventoryManagement.stocks);
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        selected,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
        onChangeDense,
        setPage,
    } = useTable({ defaultOrderBy: 'date' });
    const [searchString, setSearchString] = useState('');
    const filters = useSelector(selectIMStockMovementsFilters);

    const dataFiltered = applySortFilter({
        tableData: tableDataList.map(row => ({ ...row, hour: row.date })),
        comparator: getComparator(order, orderBy),
        searchString,
    });

    const formattedData = useMemo(() =>
        dataFiltered.map((row) => ({
            ...row,
            date: row.date ? fDateUnixUTC(row.date) : null,
            hour: row.hour ? fDateHoursUTC(row.hour) : null,
            consumedTotalQuantity: row.consumedTotalQuantity
                ? numberByLocale(row.consumedTotalQuantity, currentLang.locale) + row.measurementTypeAbbr
                : String(row.consumedTotalQuantity),
            currentTotalQuantity: row.currentTotalQuantity
                ? numberByLocale(row.currentTotalQuantity, currentLang.locale) + row.measurementTypeAbbr
                : String(row.currentTotalQuantity),
            finalTotalQuantity: row.finalTotalQuantity
                ? numberByLocale(row.finalTotalQuantity, currentLang.locale) + row.measurementTypeAbbr
                : String(row.finalTotalQuantity),
        }))
        , [currentLang.locale, dataFiltered]);

    const onSearch = (searchString) => {
        setSearchString(searchString);
        setPage(0);
    };

    const denseHeight = dense ? 56 : 76;

    return (
        <>
            {isModal &&
                <IMTableToolbar
                    searchString={searchString}
                    onSearch={onSearch}
                    exportFileTitle={translate('recentMovements')}
                    headerData={TABLE_HEAD_LIST}
                    tableData={formattedData}
                />
            }
            {!isModal && isStocksPage &&
                <IMStockMovementsTableToolbar
                    searchString={searchString}
                    onSearch={onSearch}
                    tableData={formattedData}
                    tableHeaders={TABLE_HEAD_LIST}
                    exportFileTitle={translate('stockMovements')}
                    onFilterStartDate={(newValue) => {
                        dispatch(setFilters({ startdate: newValue }));
                    }}
                    onFilterEndDate={(newValue) => {
                        dispatch(setFilters({ enddate: newValue }));
                    }}
                    filterStartDate={filters.startdate}
                    filterEndDate={filters.enddate}
                />
            }
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative', marginTop: '10px' }}>
                    {isLoading
                        ? <TableSkeleton />
                        : <Table size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD_LIST}
                                rowCount={tableDataList.length}
                                numSelected={selected.length}
                                onSort={onSort}
                            />
                            <TableBody>
                                {
                                    formattedData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow key={_uniqueId()} hover>
                                                <TableRowCellsFromHeader headers={TABLE_HEAD_LIST} row={row} />
                                            </TableRow>
                                        ))
                                }
                                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableDataList.length)} />
                                <TableNoData isNotFound={!dataFiltered.length} />
                            </TableBody>
                        </Table>
                    }
                </TableContainer>
            </Scrollbar >
            <TableFooter
                dataFiltered={dataFiltered}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                dense={dense}
                onChangeDense={onChangeDense}
            />
        </>
    )
}