import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import useLocales from '../../../hooks/useLocales';
import { activateHRStaff, selectHRStaffFilters } from '../../../redux/slices/hrStaffSlice';

HRStaffChangeActiveStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  passedData: PropTypes.array.isRequired,
  single: PropTypes.bool,
};

export default function HRStaffChangeActiveStatusModal({ open, handleClose, passedData: ids, single }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const filters = useSelector(selectHRStaffFilters);

  const handleDelete = () => {
    dispatch(activateHRStaff({
      device: window.navigator.userAgent,
      ids,
      active: filters.active ? 0 : 1,
    })).then((res) => {
      if (!res.error) {
        enqueueSnackbar(translate('changeStaffActiveStatusSuccess', { numberOfWorkers: ids.length }), { delay: 2000 });
      };
      handleClose();
    });
  };

  const getChangeActiveStatusMessage = () => {
    if (single) {
      return translate(filters.active ? 'inactivateSingleStaffMember' : 'activateSingleStaffMember');
    }
    return translate(filters.active ? 'inactivateMultipleStaffMembers' : 'activateMultipleStaffMembers');
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="staff-change-active-status-dialog">
      <DialogTitle id="staff-change-active-status-dialog">
        {translate(filters.active ? 'inactivateStaff' : 'activateStaff')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getChangeActiveStatusMessage()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
          {filters.active ? translate('inactivate') : translate('activate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
