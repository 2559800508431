import { parseISO, format, getTime, formatDistanceToNow, startOfMonth, endOfMonth } from 'date-fns';

// ----------------------------------------------------------------------

export function fDateDash(date) {
  return format(new Date(date), 'dd-MM-yyyy');
}

export function fDateUnix(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateUnixUTC(date) {
  const updDate = new Date(date).toISOString();
  const newDate = parseISO(`${updDate.substr(0, 10)} ${updDate.substr(11, 8)}`);
  return format(newDate, 'dd/MM/yyyy');
}

export function fDateHours(date) {
  return format(new Date(date), 'HH:mm');
}

export function fDateHoursUTC(date) {
  const updDate = new Date(date).toISOString();
  const newDate = parseISO(`${updDate.substr(0, 10)} ${updDate.substr(11, 8)}`);
  return format(newDate, 'HH:mm');
}

export function fDateHoursUTCUnformatted(date) {
  const updDate = new Date(date).toISOString();
  const newDate = parseISO(`${updDate.substr(0, 10)} ${updDate.substr(11, 8)}`);
  return newDate;
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fDateTimeUTC(date) {
  const updDate = new Date(date).toISOString();
  const newDate = parseISO(`${updDate.substr(0, 10)} ${updDate.substr(11, 8)}`);
  return format(newDate, 'dd/MM/yyyy HH:mm');
};

export function fDateTimeSecondsUTC(date) {
  const updDate = new Date(date).toISOString();
  const newDate = parseISO(`${updDate.substr(0, 10)} ${updDate.substr(11, 8)}`);
  return format(newDate, 'dd/MM/yyyy HH:mm:ss');
};

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fEndOfDayUTC(date) {
  const updDate = new Date(date);
  const day = updDate.getDate();
  updDate.setUTCDate(day);
  updDate.setUTCHours(23);
  updDate.setUTCMinutes(59);
  return getTime(new Date(updDate));
}

export function fStartOfMonthUTC(date) {
  const start = startOfMonth(date);
  const day = start.getDate();
  const month = start.getMonth();
  start.setUTCDate(day);
  start.setUTCMonth(month);
  start.setUTCHours(12);
  start.setUTCMinutes(0);

  return getTime(start);
}

export function fEndOfMonthUTC(date) {
  const end = endOfMonth(date);

  const day = end.getDate();
  const month = end.getMonth();
  end.setUTCDate(day);
  end.setUTCMonth(month);
  end.setUTCHours(12);
  end.setUTCMinutes(0);

  return getTime(end);
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function convertDateToUnix(date) {
  return Math.floor(date.getTime());
}

export function convertTimeStringToDate(originDate, dateTime) {
  const dateCopy = new Date(originDate);
  dateCopy.setHours(dateTime.getHours());
  dateCopy.setMinutes(dateTime.getMinutes());
  dateCopy.setSeconds(0);
  dateCopy.setMilliseconds(0);

  return dateCopy.getTime() - 60000 * dateCopy.getTimezoneOffset();
}

export function convertStringToUnixDate(dateString) {
  const [day, month, year] = dateString.split('/');
  return new Date(year, month - 1, day).getTime();
}
