import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  filters: {
    startdate: null,
    enddate: null,
    locationid: '',
    productid: '',
  },
  error: '',
};

export const fetchHarvestVsWaste = createAsyncThunk(
  'harvestVsWaste/fetchHarvestVsWaste',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/harvests/harvest-waste`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const harvestVsWasteSlice = createSlice({
  name: 'harvestVsWasteSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
        productid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvestVsWaste.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestVsWaste.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchHarvestVsWaste.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = harvestVsWasteSlice.actions;

export default harvestVsWasteSlice.reducer;

export const selectHarvestVsWasteFilters = (state) => state.harvestVsWaste.filters;
export const selectHarvestVsWasteData = (state) => state.harvestVsWaste.data;
export const selectHarvestVsWasteLoading = (state) => state.harvestVsWaste.isLoading;
export const selectHarvestVsWasteError = (state) => state.harvestVsWaste.error;
