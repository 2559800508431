import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    imStockByDay: [],
    filters: {
        date: null,
        stockid: null,
        articletypeid: null,
    },
    error: '',
};

export const fetchIMStockByDay = createAsyncThunk(
    'imStockByDay/fetchIMStockByDay',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/stockarticle/stock-by-day`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const imStockByDaySlice = createSlice({
    name: 'imStockByDaySlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                date: null,
                stockid: null,
                articletypeid: null,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchIMStockByDay.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMStockByDay.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.imStockByDay = payload.data;
        });
        builder.addCase(fetchIMStockByDay.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
    },
});

export const { setFilters, resetFilters } = imStockByDaySlice.actions;

export default imStockByDaySlice.reducer;

export const selectIMStockByDayData = (state) => state.imStockByDay.imStockByDay;
export const selectIMStockByDayLoading = (state) => state.imStockByDay.isLoading;
export const selectIMStockByDayError = (state) => state.imStockByDay.error;
export const selectIMStockByDayFilters = (state) => state.imStockByDay.filters;
