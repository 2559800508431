import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  filters: {
    locationid: '',
  },
  error: '',
};

export const fetchFertirrigationTanks = createAsyncThunk(
  'fertirrigationTanks/fetchFertirrigationTanks',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/tank`, {
        params: { ...params },
      });

      return response.data.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const fertirrigationTanksSlice = createSlice({
  name: 'fertirrigationTanksSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFertirrigationTanks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFertirrigationTanks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.tanks;
      state.alerts = payload.alerts;
    });
    builder.addCase(fetchFertirrigationTanks.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setFilters, resetFilters } = fertirrigationTanksSlice.actions;

export default fertirrigationTanksSlice.reducer;

export const selectFertirrigationTanksFilters = (state) => state.fertirrigationTanks.filters;
export const selectFertirrigationTanksData = (state) => state.fertirrigationTanks.data;
export const selectFertirrigationTanksAlertData = (state) => state.fertirrigationTanks.alerts;
export const selectFertirrigationTanksLoading = (state) => state.fertirrigationTanks.isLoading;
export const selectFertirrigationTanksError = (state) => state.fertirrigationTanks.error;
