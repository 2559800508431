import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default function RHFSelect({ name, children, label, disabled }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel error={!!error} id={field.name}>
            {label}
          </InputLabel>
          <Select {...field} error={!!error} id={field.name} label={label} disabled={disabled}>
            {children}
          </Select>
          {error?.message && <FormHelperText error={!!error}>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
