import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import useLocales from '../../../hooks/useLocales';

AMUnsavedChangesModal.propTypes = {
  passedData: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function AMUnsavedChangesModal({ open, handleClose, passedData }) {
  const { translate } = useLocales();

  const onContinue = () => {
    if (passedData) passedData();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="unsaved-changes">
      <DialogTitle id="unsaved-changes">
        {translate('changesAreNotSaved')}
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onContinue} autoFocus>
          {translate('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
