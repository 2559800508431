import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    Card,
} from '@mui/material';
import _uniqueId from 'lodash/uniqueId';
import { useSnackbar } from 'notistack';

import {
    selectFertirrigationTankRefillItems,
    selectFertirrigationTankRefillItemsError,
    selectFertirrigationTankRefillItemsLoading,
    fetchFertirrigationTankRefillItems
} from '../../../redux/slices/fertirrigationTankRefillItemsSlice';

import {
    TableSkeleton,
    TableNoData,
    TableHeadCustom,
    TableEmptyRows,
} from '../../../components/table';
import FertirrigationTableRow from '../../fertirrigation/list/FertirrigationTableRow';
import TableFooter from '../../../components/table/TableFooter';

import useLocales from '../../../hooks/useLocales';
import useTable, { getComparator, emptyRows, } from '../../../hooks/useTable';

import { applySortFilter } from '../../../utils/misc';

FertirrigationTankRefillModal.propTypes = {
    passedData: PropTypes.number,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

const TABLE_REFILL_ITEMS = [
    { id: 'articleName', label: 'name', sortable: true, show: true, align: 'left' },
    { id: 'articleTypeName', label: 'itemType', sortable: true, show: true, align: 'center' },
    { id: 'stockName', label: 'stock', sortable: true, show: true, align: 'center' },
    { id: 'quantity', label: 'quantity', sortable: false, show: true, align: 'center' },
]

export default function FertirrigationTankRefillModal({ passedData, open, handleClose }) {
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        dense,
        onChangeDense,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({ defaultOrderBy: 'articleName', defaultRowsPerPage: 10 });

    const isLoading = useSelector(selectFertirrigationTankRefillItemsLoading);
    const error = useSelector(selectFertirrigationTankRefillItemsError);
    const refillItems = useSelector(selectFertirrigationTankRefillItems);

    useEffect(() => {
        dispatch(fetchFertirrigationTankRefillItems(passedData));
    }, [dispatch, passedData]);

    useEffect(() => {
        if (!error) return;
        enqueueSnackbar(error, { variant: 'error', delay: 2000 });
    }, [error, enqueueSnackbar]);

    const dataFiltered = applySortFilter({
        tableData: refillItems.map(refillItem => ({
            ...refillItem,
            quantity: `${refillItem.quantity} ${refillItem.articleAbbr}`
        })),
        comparator: getComparator(order, orderBy),
    });

    const isNotFound = !dataFiltered.length;
    const denseHeight = dense ? 56 : 76;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('refillItems')}</DialogTitle>
            <DialogContent>
                <Card>
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <Table size={dense ? 'small' : 'medium'} sx={{ mt: 2 }}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_REFILL_ITEMS}
                                onSort={onSort}
                            />
                            <TableBody>
                                {
                                    dataFiltered
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) =>
                                            <FertirrigationTableRow
                                                headerData={TABLE_REFILL_ITEMS}
                                                key={_uniqueId()}
                                                row={row}
                                            />
                                        )
                                }
                                <TableEmptyRows
                                    height={denseHeight}
                                    emptyRows={emptyRows(page, rowsPerPage, refillItems.length)}
                                />
                                <TableNoData isNotFound={isNotFound} />
                            </TableBody>
                        </Table>
                    )}
                    <TableFooter
                        dataFiltered={dataFiltered}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
                    {translate('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
