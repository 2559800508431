import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  editedScrap: null,
  filters: {
    startdate: null,
    enddate: null,
    locationid: '',
    productid: '',
  },
  error: '',
};

export const fetchHarvestScrap = createAsyncThunk('harvestScrap/fetchScrap', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/waste`, {
      params: { ...params },
    });

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const fetchHarvestScrapSingle = createAsyncThunk(
  'harvestScrap/fetchScrapSingle',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/waste/${id}`);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const updateScrap = createAsyncThunk('harvestScrap/updateScrap', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/waste`, { ...data });

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const createScrap = createAsyncThunk('harvestScrap/createScrap', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/waste`, { ...data });

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const deleteHarvestScrap = createAsyncThunk('harvestScrap/deleteScrap', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/waste`, {
      data: {
        ids: data.data,
        device: data.device,
      },
    });

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

const harvestScrapSlice = createSlice({
  name: 'harvestScrap',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
        productid: '',
      };
    },
    resetEditedScrap(state) {
      state.editedScrap = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvestScrap.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestScrap.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchHarvestScrap.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteHarvestScrap.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHarvestScrap.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteHarvestScrap.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchHarvestScrapSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestScrapSingle.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.editedScrap = payload.data;
    });
    builder.addCase(fetchHarvestScrapSingle.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetEditedScrap, resetFilters } = harvestScrapSlice.actions;

export default harvestScrapSlice.reducer;

export const selectHarvestScrapFilters = (state) => state.harvestScrap.filters;
export const selectHarvestScrapData = (state) => state.harvestScrap.data;
export const selectHarvestScrapLoading = (state) => state.harvestScrap.isLoading;
export const selectHarvestScrapError = (state) => state.harvestScrap.error;
export const selectEditedHarvestScrapSingle = (state) => state.harvestScrap.editedScrap;
