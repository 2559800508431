import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteWorker, fetchWorkers } from '../../../redux/slices/operationsWorkMapsSlice';
import useLocales from '../../../hooks/useLocales';

WorkMapsWorkersDeleteModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  single: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function WorkMapsWorkersDeleteModal({ passedData, open, handleClose, single }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const handleDelete = () => {
    dispatch(deleteWorker({ device: window.navigator.userAgent, data: passedData.ids })).then(() => {
      dispatch(fetchWorkers(passedData.id));
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="remove-harvest-quality-control-dialog">
      <DialogTitle id="remove-harvest-entries-dialog">{translate('deletePopupTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {single ? translate('deletePopupMessageOneItem') : translate('deletePopupMessageMultipleItems')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
