import { configureStore } from '@reduxjs/toolkit';
import harvestEntriesReducer from './slices/harvestEntriesSlice';
import harvestScrapReducer from './slices/harvestScrapSlice';
import harvestVsWasteReducer from './slices/harvestVsWasteSlice';
import harvestMassMovementReducer from './slices/harvestMassMovementSlice';
import harvestProductivityReducer from './slices/harvestProductivitySlice';
import harvestPackagingReducer from './slices/harvestPackagingSlice';
import fertirrigationDrainageReducer from './slices/fertirrigationDrainageSlice';
import fertirrigationSubstrateReducer from './slices/fertirrigationSubstrateSlice';
import fertirrigationTanksReducer from './slices/fertirrigationTanksSlice';
import fertirrigationTankReducer from './slices/fertirrigationTankSlice';
import fertirrigationTankRefillItemsReducer from './slices/fertirrigationTankRefillItemsSlice';
import hrAbsenceBookSliceReducer from './slices/hrAbsenceBookSlice';
import hrTimeSheetsSliceReducer from './slices/hrTimeSheetsSlice';
import hrPayrollSliceReducer from './slices/hrPayrollSlice';
import hrStaffSliceReducer from './slices/hrStaffSlice';
import hrStaffDocumentsSliceReducer from './slices/hrStaffDocumentsSlice';
import hrWorkerFeedbackSliceReducer from './slices/hrWorkerFeedbackSlice';
import operationsActivityTimeReportSliceReducer from './slices/operationsActivityTimeReportSlice';
import harvestQualityControlReducer from './slices/harvestQualityControlSlice';
import operationsWorkingNowReducer from './slices/operationsWorkingNowSlice';
import operationsWorkMapsReducer from './slices/operationsWorkMapsSlice';
import operationsWorkHoursReducer from './slices/operationsWorkHoursSlice';
import operationsDaysOffPlanningReducer from './slices/operationsDaysOffPlanningSlice';
import operationsChecklistReportReducer from './slices/operationsChecklistReportSlice';
import parametersAbsenceReducer from './slices/parametersAbsenceSlice';
import parametersWorkerFeedbackReducer from './slices/parametersWorkerFeedbackSlice';
import parametersPickingBonusReducer from './slices/parametersPickingBonusSlice';
import parametersDocumentTypesReducer from './slices/parametersDocumentTypesSlice';
import parametersCropReducer from './slices/parametersCropSlice';
import parametersReportingReducer from './slices/parametersReportingSlice';
import parametersLocationsReducer from './slices/parametersLocationsSlice';
import parametersCropContainersReducer from './slices/parametersCropContainersSlice';
import parametersCropQualityControlReducer from './slices/parametersCropQualityControlSlice';
import parametersCompaniesSlice from './slices/parametersCompaniesSlice';
import parametersWorkTypesSlice from './slices/parametersWorkTypesSlice';
import selectorsReducer from './slices/selectorsSlice';
import consumptionElectricityReducer from './slices/consumptionElectricitySlice';
import consumptionWaterReducer from './slices/consumptionWaterSlice';
import consumptionFuelReducer from './slices/consumptionFuelSlice';
import imStocksReducer from './slices/imStocksSlice';
import imItemsReducer from './slices/imItemsSlice';
import imStockMovementsReducer from './slices/imStockMovementsSlice';
import imStockByDayReducer from './slices/imStockByDaySlice';
import dashboardAnalyticsReducer from './slices/dashboardAnalyticsSlice';
import amPortalPermissionsReducer from './slices/amPortalPermissionsSlice';
import amLoginsReducer from './slices/amLoginsSlice';

export const store = configureStore({
  reducer: {
    harvestEntries: harvestEntriesReducer,
    harvestScrap: harvestScrapReducer,
    harvestVsWaste: harvestVsWasteReducer,
    harvestMassMovement: harvestMassMovementReducer,
    harvestProductivity: harvestProductivityReducer,
    harvestPackaging: harvestPackagingReducer,
    harvestQualityControl: harvestQualityControlReducer,
    fertirrigationDrainage: fertirrigationDrainageReducer,
    fertirrigationSubstrate: fertirrigationSubstrateReducer,
    fertirrigationTanks: fertirrigationTanksReducer,
    fertirrigationTank: fertirrigationTankReducer,
    fertirrigationTankRefillItems: fertirrigationTankRefillItemsReducer,
    hrAbsenceBook: hrAbsenceBookSliceReducer,
    hrTimeSheets: hrTimeSheetsSliceReducer,
    hrPayroll: hrPayrollSliceReducer,
    hrStaff: hrStaffSliceReducer,
    hrStaffDocuments: hrStaffDocumentsSliceReducer,
    hrWorkerFeedback: hrWorkerFeedbackSliceReducer,
    consumptionElectricity: consumptionElectricityReducer,
    consumptionWater: consumptionWaterReducer,
    consumptionFuel: consumptionFuelReducer,
    imStocks: imStocksReducer,
    imStockMovements: imStockMovementsReducer,
    imStockByDay: imStockByDayReducer,
    imItems: imItemsReducer,
    operationsActivityTimeReport: operationsActivityTimeReportSliceReducer,
    operationsWorkingNow: operationsWorkingNowReducer,
    operationsWorkMaps: operationsWorkMapsReducer,
    operationsWorkHours: operationsWorkHoursReducer,
    operationsChecklistReport: operationsChecklistReportReducer,
    operationsDaysOffPlanning: operationsDaysOffPlanningReducer,
    parametersAbsence: parametersAbsenceReducer,
    parametersWorkerFeedback: parametersWorkerFeedbackReducer,
    parametersPickingBonus: parametersPickingBonusReducer,
    parametersDocumentTypes: parametersDocumentTypesReducer,
    parametersCrop: parametersCropReducer,
    parametersReporting: parametersReportingReducer,
    parametersLocations: parametersLocationsReducer,
    parametersCropContainers: parametersCropContainersReducer,
    parametersCropQualityControl: parametersCropQualityControlReducer,
    parametersCompanies: parametersCompaniesSlice,
    parametersWorkTypes: parametersWorkTypesSlice,
    dashboardAnalytics: dashboardAnalyticsReducer,
    amPortalPermissions: amPortalPermissionsReducer,
    amLogins: amLoginsReducer,
    selectors: selectorsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: process.env.NODE_ENV === 'development',
});
