import { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { noCase } from 'change-case';
import { Divider, Stack, MenuItem, Switch, FormGroup, FormControlLabel } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import LanguagePopover from './LanguagePopover';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: PATH_DASHBOARD.root,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { translate } = useLocales();
  const [open, setOpen] = useState(null);
  const { onToggleMode, themeMode } = useSettings();

  const { logout } = useAuth();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate onClick={handleOpen}>
        <Iconify icon="ci:settings-filled" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem>
            <LanguagePopover title={translate('language')} />
          </MenuItem>
          {MENU_OPTIONS.map((option) => (
            <MenuItem component={Link} key={option.label} to={option.linkTo} onClick={handleClose}>
              {translate(noCase(option.label))}
            </MenuItem>
          ))}
          <MenuItem onChange={onToggleMode}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={themeMode === 'dark'} onChange={onToggleMode} />}
                label={translate(noCase(themeMode))}
              />
            </FormGroup>
          </MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={logout}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
