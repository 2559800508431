// Modal Types
export const HARVEST_ENTRIES_DELETE = 'HARVEST_ENTRIES_DELETE';
export const HARVEST_ENTRIES_DELETE_SINGLE = 'HARVEST_ENTRIES_DELETE_SINGLE';
export const HARVEST_SCRAP_DELETE = 'HARVEST_SCRAP_DELETE';
export const HARVEST_SCRAP_DELETE_SINGLE = 'HARVEST_SCRAP_DELETE_SINGLE';
export const HARVEST_QUALITY_CONTROL_DELETE = 'HARVEST_QUALITY_CONTROL_DELETE';
export const HARVEST_QUALITY_CONTROL_DELETE_SINGLE = 'HARVEST_QUALITY_CONTROL_DELETE_SINGLE';
export const HARVEST_QUALITY_CONTROL_WORKERS_DELETE = 'HARVEST_QUALITY_CONTROL_WORKERS_DELETE';
export const OPERATIONS_WORK_MAPS_WORKERS_DELETE = 'OPERATIONS_WORK_MAPS_WORKERS_DELETE';
export const OPERATIONS_WORK_MAPS_WORKERS_DELETE_SINGLE = 'OPERATIONS_WORK_MAPS_WORKERS_DELETE_SINGLE';
export const OPERATIONS_WORK_HOURS_DELETE_SINGLE = 'OPERATIONS_WORK_HOURS_DELETE_SINGLE';
export const OPERATIONS_WORK_HOURS_DELETE = 'OPERATIONS_WORK_HOURS_DELETE';
export const FERTIRRIGATION_DRAINAGE_DELETE_SINGLE = 'FERTIRRIGATION_DRAINAGE_DELETE_SINGLE';
export const FERTIRRIGATION_DRAINAGE_DELETE = 'FERTIRRIGATION_DRAINAGE_DELETE';
export const FERTIRRIGATION_SUBSTRATE_DELETE_SINGLE = 'FERTIRRIGATION_SUBSTRATE_DELETE_SINGLE';
export const FERTIRRIGATION_SUBSTRATE_DELETE = 'FERTIRRIGATION_SUBSTRATE_DELETE';
export const FERTIRRIGATION_TANK_REFILL = 'FERTIRRIGATION_TANK_REFILL';
export const HR_ABSENCE_BOOK_DELETE = 'HR_ABSENCE_BOOK_DELETE';
export const HR_ABSENCE_BOOK_DELETE_SINGLE = 'HR_ABSENCE_BOOK_DELETE_SINGLE';
export const HR_WORKER_FEEDBACK_DELETE = 'HR_WORKER_FEEDBACK_DELETE';
export const HR_WORKER_FEEDBACK_DELETE_SINGLE = 'HR_WORKER_FEEDBACK_DELETE_SINGLE';
export const HR_STAFF_DELETE = 'HR_STAFF_DELETE';
export const HR_STAFF_DELETE_SINGLE = 'HR_STAFF_DELETE_SINGLE';
export const HR_STAFF_CHANGE_ACTIVE_STATUS = 'HR_STAFF_CHANGE_ACTIVE_STATUS';
export const HR_STAFF_CHANGE_ACTIVE_STATUS_SINGLE = 'HR_STAFF_CHANGE_ACTIVE_STATUS_SINGLE';
export const HR_STAFF_ADD_DOCUMENT = 'HR_STAFF_ADD_DOCUMENT';
export const HR_STAFF_EDIT_DOCUMENT = 'HR_STAFF_EDIT_DOCUMENT';
export const HR_STAFF_DOCUMENTS_DELETE_SINGLE = 'HR_STAFF_DOCUMENTS_DELETE_SINGLE';
export const HR_STAFF_DOCUMENTS_DELETE = 'HR_STAFF_DOCUMENTS_DELETE';
export const HR_STAFF_MANAGE_ACCESS = 'HR_STAFF_MANAGE_ACCESS';
export const CONSUMPTION_ELECTRICITY_DELETE = 'CONSUMPTION_ELECTRICITY_DELETE';
export const CONSUMPTION_WATER_DELETE = 'CONSUMPTION_WATER_DELETE';
export const CONSUMPTION_FUEL_DELETE = 'CONSUMPTION_FUEL_DELETE';
export const PARAMETERS_ABSENCE_DELETE_SINGLE = 'PARAMETERS_ABSENCE_DELETE_SINGLE';
export const PARAMETERS_WORKER_FEEDBACK_DELETE_SINGLE = 'PARAMETERS_WORKER_FEEDBACK_DELETE_SINGLE';
export const PARAMETERS_PICKING_BONUS_DELETE_SINGLE = 'PARAMETERS_PICKING_BONUS_DELETE_SINGLE';
export const PARAMETERS_DOCUMENT_TYPES_DELETE_SINGLE = 'PARAMETERS_DOCUMENT_TYPES_DELETE_SINGLE';
export const PARAMETERS_CROP_DELETE_SINGLE = 'PARAMETERS_CROP_DELETE_SINGLE';
export const PARAMETERS_CROP_CONTAINERS_DELETE_SINGLE = 'PARAMETERS_CROP_CONTAINERS_DELETE_SINGLE';
export const PARAMETERS_CROP_QUALITY_CONTROL_DELETE_SINGLE = 'PARAMETERS_CROP_QUALITY_CONTROL_DELETE_SINGLE';
export const PARAMETERS_COMPANY_DELETE_SINGLE = 'PARAMETERS_COMPANY_DELETE_SINGLE';
export const PARAMETERS_LOCATIONS_DELETE_SINGLE = 'PARAMETERS_LOCATIONS_DELETE_SINGLE';
export const PARAMETERS_REPORTING_DELETE_SINGLE = 'PARAMETERS_REPORTING_DELETE_SINGLE';
export const PARAMETERS_WORK_TYPES_DELETE_SINGLE = 'PARAMETERS_WORK_TYPES_DELETE_SINGLE';
export const IM_STOCKS_DELETE_SINGLE = 'IM_STOCKS_DELETE_SINGLE';
export const IM_ITEMS_DELETE_SINGLE = 'IM_ITEMS_DELETE_SINGLE';
export const IM_ITEM_TYPES_DELETE_SINGLE = 'IM_ITEM_TYPES_DELETE_SINGLE';
export const IM_STOCK_ITEMS_MODAL = 'IM_STOCK_ITEMS_MODAL';
export const IM_STOCK_RECENT_MOVEMENTS_MODAL = 'IM_STOCK_RECENT_MOVEMENTS_MODAL';
export const HR_STAFF_RESET_PASSWORD = 'HR_STAFF_RESET_PASSWORD';
export const HR_STAFF_DELETE_ACCESS = 'HR_STAFF_DELETE_ACCESS';
export const AM_UNSAVED_CHANGES = 'AM_UNSAVED_CHANGES';
export const HR_STAFF_UPLOAD_WORKERS_BY_FILE = 'HR_STAFF_UPLOAD_WORKERS_BY_FILE';

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
];
