import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import useLocales from '../../../hooks/useLocales';

import { deleteHRStaffMemberAccess } from '../../../redux/slices/hrStaffSlice';
import { fetchAMLogins, selectAMLoginsFilters } from '../../../redux/slices/amLoginsSlice';

import { PATH_DASHBOARD } from '../../../routes/paths';

HRStaffDeleteAccessModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function HRStaffDeleteAccessModal({ passedData, open, handleClose }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const filters = useSelector(selectAMLoginsFilters);

  const handleResetPassword = () => {
    dispatch(deleteHRStaffMemberAccess({ device: window.navigator.userAgent, id: passedData })).then((res) => {
      if (res.error) {
        enqueueSnackbar(res.payload, { variant: 'error', delay: 2000 });
        return;
      }
      enqueueSnackbar(translate('update_successful'), { delay: 2000 });
      if (location.pathname === PATH_DASHBOARD.accessManagement.access) {
        dispatch(fetchAMLogins({ ...filters }));
      };
      handleClose();
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="staff-delete-access">
      <DialogTitle id="staff-delete-access">
        {translate('deleteAccess')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('deleteAccessText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleResetPassword} autoFocus>
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
