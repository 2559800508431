// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = {
  dashboard: '/dashboard',
  harvest: '/harvest',
  operations: '/operations',
  fertirrigation: '/fertirrigation',
  humanResources: '/human-resources',
  inventoryManagement: '/inventory-management',
  consumption: '/consumption',
  parameters: '/parameters',
  accessManagement: '/access-management',
};

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD.dashboard,
  harvest: {
    entries: path(ROOTS_DASHBOARD.harvest, '/entries'),
    entriesEdit: path(ROOTS_DASHBOARD.harvest, '/entries/edit/:id'),
    scrap: path(ROOTS_DASHBOARD.harvest, '/scrap'),
    scrapEdit: path(ROOTS_DASHBOARD.harvest, '/scrap/edit/:id'),
    scrapCreate: path(ROOTS_DASHBOARD.harvest, '/scrap/create'),
    qualityControl: path(ROOTS_DASHBOARD.harvest, '/quality-control'),
    qualityControlEdit: path(ROOTS_DASHBOARD.harvest, '/quality-control/edit/:id'),
    summary: path(ROOTS_DASHBOARD.harvest, '/summary'),
    massMovement: path(ROOTS_DASHBOARD.harvest, '/mass-movement'),
    harvestVsWaste: path(ROOTS_DASHBOARD.harvest, '/harvest-vs-waste'),
    productivity: path(ROOTS_DASHBOARD.harvest, '/productivity'),
    packaging: path(ROOTS_DASHBOARD.harvest, '/packaging'),
  },
  operations: {
    workMaps: path(ROOTS_DASHBOARD.operations, '/work-maps'),
    workMapsEdit: path(ROOTS_DASHBOARD.operations, '/work-maps/edit/:id'),
    checklistReport: path(ROOTS_DASHBOARD.operations, '/checklist-report'),
    workHours: path(ROOTS_DASHBOARD.operations, '/work-hours'),
    workHoursEdit: path(ROOTS_DASHBOARD.operations, '/work-hours/edit/:id'),
    workingNow: path(ROOTS_DASHBOARD.operations, '/working-now'),
    activityTimeReport: path(ROOTS_DASHBOARD.operations, '/activity-time-report'),
    daysOffPlanning: path(ROOTS_DASHBOARD.operations, '/days-off-planning'),
  },
  fertirrigation: {
    tanks: path(ROOTS_DASHBOARD.fertirrigation, '/tanks'),
    tanksEdit: path(ROOTS_DASHBOARD.fertirrigation, '/tanks/edit/:id'),
    drainage: path(ROOTS_DASHBOARD.fertirrigation, '/drainage'),
    substrate: path(ROOTS_DASHBOARD.fertirrigation, '/substrate'),
  },
  humanResources: {
    absenceBook: path(ROOTS_DASHBOARD.humanResources, '/absence-book'),
    absenceBookEdit: path(ROOTS_DASHBOARD.humanResources, '/absence-book/edit/:id'),
    absenceBookCreate: path(ROOTS_DASHBOARD.humanResources, '/absence-book/create'),
    timesheets: path(ROOTS_DASHBOARD.humanResources, '/timesheets'),
    payroll: path(ROOTS_DASHBOARD.humanResources, '/payroll'),
    staff: path(ROOTS_DASHBOARD.humanResources, '/staff'),
    staffEdit: path(ROOTS_DASHBOARD.humanResources, '/staff/edit/:id'),
    staffCreate: path(ROOTS_DASHBOARD.humanResources, '/staff/create'),
    workerFeedback: path(ROOTS_DASHBOARD.humanResources, '/worker-feedback'),
    workerFeedbackEdit: path(ROOTS_DASHBOARD.humanResources, '/worker-feedback/edit/:id'),
    workerFeedbackCreate: path(ROOTS_DASHBOARD.humanResources, '/worker-feedback/create'),
  },
  inventoryManagement: {
    items: path(ROOTS_DASHBOARD.inventoryManagement, '/items'),
    itemsCreate: path(ROOTS_DASHBOARD.inventoryManagement, '/items/create'),
    itemsEdit: path(ROOTS_DASHBOARD.inventoryManagement, '/items/edit/:id'),
    stocks: path(ROOTS_DASHBOARD.inventoryManagement, '/stocks'),
    stocksCreate: path(ROOTS_DASHBOARD.inventoryManagement, '/stocks/create'),
    stocksEdit: path(ROOTS_DASHBOARD.inventoryManagement, '/stocks/edit/:id'),
  },
  consumption: {
    electricity: path(ROOTS_DASHBOARD.consumption, '/electricity'),
    electricityCreate: path(ROOTS_DASHBOARD.consumption, '/electricity/create'),
    electricityEdit: path(ROOTS_DASHBOARD.consumption, '/electricity/edit/:id'),
    water: path(ROOTS_DASHBOARD.consumption, '/water'),
    waterCreate: path(ROOTS_DASHBOARD.consumption, '/water/create'),
    waterEdit: path(ROOTS_DASHBOARD.consumption, '/water/edit/:id'),
    fuel: path(ROOTS_DASHBOARD.consumption, '/fuel'),
    fuelCreate: path(ROOTS_DASHBOARD.consumption, '/fuel/create'),
    fuelEdit: path(ROOTS_DASHBOARD.consumption, '/fuel/edit/:id'),
  },
  parameters: {
    humanResources: path(ROOTS_DASHBOARD.parameters, '/human-resources'),
    humanResourcesCreate: path(ROOTS_DASHBOARD.parameters, '/human-resources/create'),
    humanResourcesEdit: path(ROOTS_DASHBOARD.parameters, '/human-resources/edit/:id'),
    farm: path(ROOTS_DASHBOARD.parameters, '/farm'),
    farmCreate: path(ROOTS_DASHBOARD.parameters, '/farm/create'),
    farmEdit: path(ROOTS_DASHBOARD.parameters, '/farm/edit/:id'),
    reporting: path(ROOTS_DASHBOARD.parameters, '/reporting'),
    reportingCreate: path(ROOTS_DASHBOARD.parameters, '/reporting/create'),
    reportingEdit: path(ROOTS_DASHBOARD.parameters, '/reporting/edit/:id'),
  },
  accessManagement: {
    permissions: path(ROOTS_DASHBOARD.accessManagement, '/permissions'),
    access: path(ROOTS_DASHBOARD.accessManagement, '/access'),
    accessEdit: path(ROOTS_DASHBOARD.accessManagement, '/access/edit/:id'),
    accessCreate: path(ROOTS_DASHBOARD.accessManagement, '/access/create'),
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
