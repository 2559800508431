import React from 'react';
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

ErrorMessageBox.propTypes = {
    error: PropTypes.string,
};

function ErrorMessageBox({ error }) {
    if (!error) return null;
    return (
        <Box
            sx={{
                color: 'black',
                fontSize: '1rem',
                mt: 1,
                backgroundColor: 'error.light',
                borderRadius: 1,
                px: 1.5,
                py: 0.5,
            }}
        >
            {error}
        </Box>
    )
};

export default ErrorMessageBox;