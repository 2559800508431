import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  workerFeedback: [],
  workerFeedbackById: {},
  filters: {
    startdate: null,
    enddate: null,
    userid: null,
    companyid: null,
    typeid: null,
  },
  error: '',
};

export const fetchHRWorkerFeedback = createAsyncThunk(
  'hrWorkerFeedback/fetchHRWorkerFeedback',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluation/workerfeedback`, {
        params,
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchHRWorkerFeedbackById = createAsyncThunk(
  'hrWorkerFeedback/fetchHRWorkerFeedbackById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluation/${id}`);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const createHRWorkerFeedback = createAsyncThunk(
  'hrWorkerFeedback/createHRWorkerFeedback',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/evaluation`, data);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const updateHRWorkerFeedback = createAsyncThunk(
  'hrWorkerFeedback/updateHRWorkerFeedback',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/evaluation`, data);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteHRWorkerFeedback = createAsyncThunk(
  'hrWorkerFeedback/deleteHRWorkerFeedback',
  async ({ device, data }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/evaluation`, {
        data: {
          ids: data,
          device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const hrWorkerFeedbackSlice = createSlice({
  name: 'hrWorkerFeedbackSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        userid: null,
        companyid: null,
        typeid: null,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHRWorkerFeedback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHRWorkerFeedback.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.workerFeedback = payload.data;
    });
    builder.addCase(fetchHRWorkerFeedback.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createHRWorkerFeedback.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(createHRWorkerFeedback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createHRWorkerFeedback.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateHRWorkerFeedback.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(updateHRWorkerFeedback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateHRWorkerFeedback.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchHRWorkerFeedbackById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.workerFeedbackById = payload.data;
      state.error = '';
    });
    builder.addCase(fetchHRWorkerFeedbackById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHRWorkerFeedbackById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteHRWorkerFeedback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHRWorkerFeedback.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteHRWorkerFeedback.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setFilters, resetFilters } = hrWorkerFeedbackSlice.actions;

export default hrWorkerFeedbackSlice.reducer;

export const selectHRWorkerFeedbackFilters = (state) => state.hrWorkerFeedback.filters;
export const selectHRWorkerFeedbackData = (state) => state.hrWorkerFeedback.workerFeedback;
export const selectHRWorkerFeedbackDataById = (state) => state.hrWorkerFeedback.workerFeedbackById;
export const selectHRWorkerFeedbackLoading = (state) => state.hrWorkerFeedback.isLoading;
export const selectHRWorkerFeedbackError = (state) => state.hrWorkerFeedback.error;
