import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: {
    headers: {},
    values: [],
  },
  filters: {
    startdate: null,
    enddate: null,
    userid: null,
    companyid: null,
    locationid: '',
    worktypeid: null,
  },
  error: '',
};

export const fetchHRTimeSheets = createAsyncThunk(
  'hrTimeSheets/fetchHRTimeSheets',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers/timesheets`, {
        params,
      });

      return response.data.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const hrTimeSheetsSlice = createSlice({
  name: 'hrTimeSheetsSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        userid: null,
        companyid: null,
        locationid: '',
        worktypeid: null,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHRTimeSheets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHRTimeSheets.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = {
        values: payload.data,
        headers: payload.header,
      };
    });
    builder.addCase(fetchHRTimeSheets.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setFilters, resetFilters } = hrTimeSheetsSlice.actions;

export default hrTimeSheetsSlice.reducer;

export const selectHRTimeSheetsFilters = (state) => state.hrTimeSheets.filters;
export const selectHRTimeSheetsData = (state) => state.hrTimeSheets.data;
export const selectHRTimeSheetsLoading = (state) => state.hrTimeSheets.isLoading;
export const selectHRTimeSheetsError = (state) => state.hrTimeSheets.error;
