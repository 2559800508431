import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  workers: [],
  providers: [],
  companies: [],
  locations: [],
  supervisors: [],
  registers: [],
  harvestTypes: [],
  packers: [],
  roles: [],
  workingMapTypes: [],
  absenceTypes: [],
  behaviorTypes: [],
  documentTypes: [],
  fuelTypes: [],
  reportTypes: [],
  operationTypes: [],
  stocksList: [],
  itemsList: [],
  itemTypesList: [],
  unitTypesList: [],
  modulesList: [],
};

export const fetchWorkers = createAsyncThunk('selectors/fetchUsers', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users/select`);

  return response.data;
});

export const fetchPackers = createAsyncThunk('selectors/fetchPackers', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users/packer-list`);

  return response.data;
});

export const fetchProviders = createAsyncThunk('selectors/fetchProviders', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/companies`);

  return response.data;
});

export const fetchCompanies = createAsyncThunk('selectors/fetchCompanies', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/companies/select`);

  return response.data;
});

export const fetchLocations = createAsyncThunk('selectors/fetchLocations', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/locations/select`);

  return response.data;
});

export const fetchSupervisors = createAsyncThunk('selectors/fetchSupervisors', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users/select?supervisors=1`);

  return response.data;
});

export const fetchHarvestTypes = createAsyncThunk('selectors/fetchHarvestTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/products/select`);

  return response.data;
});

export const fetchRoles = createAsyncThunk('selectors/fetchRoles', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/roles`);

  return response.data;
});

export const fetchWorkingMapTypes = createAsyncThunk('selectors/fetchWorkingMapTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmaptype`);

  return response.data;
});

export const fetchAbsenceTypes = createAsyncThunk('selectors/fetchAbsenceTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/wfmtype/select`);

  return response.data;
});

export const fetchBehaviorTypes = createAsyncThunk('selectors/fetchBehaviorTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype/select?type=2`);

  return response.data;
});

export const fetchDocumentTypes = createAsyncThunk('selectors/fetchDocumentTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/documenttype/select`);

  return response.data;
});

export const fetchFuelTypes = createAsyncThunk('selectors/fetchFuelTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/fueltype/select`);

  return response.data;
});

export const fetchReportTypes = createAsyncThunk('selectors/fetchReportTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/dispatcher/select`);

  return response.data;
});

export const fetchOperationTypes = createAsyncThunk('selectors/fetchOperationTypes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/operationtype/select`);

  return response.data;
});

export const fetchStocksList = createAsyncThunk('selectors/fetchStocksList', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/stock/select`);

  return response.data;
});

export const fetchItemsList = createAsyncThunk('selectors/fetchItemsList', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/article`);

  return response.data;
});

export const fetchItemTypesList = createAsyncThunk('selectors/fetchItemTypesList', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/articletype/select`);

  return response.data;
});

export const fetchUnitTypesList = createAsyncThunk('selectors/fetchUnitTypesList', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/measurementtype/select`);

  return response.data;
});

export const fetchModulesList = createAsyncThunk('selectors/fetchModulesList', async () => {
  const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/portalpermissions/select_modules`);

  return response.data;
});

const selectorsSlice = createSlice({
  name: 'selectors',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchWorkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.workers = payload.data;
      state.registers = payload.data;
    });
    builder.addCase(fetchProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProviders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.providers = payload.data;
    });
    builder.addCase(fetchCompanies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.companies = payload.data;
    });
    builder.addCase(fetchLocations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLocations.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.locations = payload.data;
    });
    builder.addCase(fetchSupervisors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSupervisors.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.supervisors = payload.data;
    });
    builder.addCase(fetchHarvestTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.harvestTypes = payload.data;
    });
    builder.addCase(fetchPackers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPackers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.packers = payload.data;
    });
    builder.addCase(fetchRoles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.roles = payload.data;
    });
    builder.addCase(fetchWorkingMapTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkingMapTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.workingMapTypes = payload.data;
    });
    builder.addCase(fetchAbsenceTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAbsenceTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.absenceTypes = payload.data;
    });
    builder.addCase(fetchBehaviorTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBehaviorTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.behaviorTypes = payload.data;
    });
    builder.addCase(fetchDocumentTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocumentTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.documentTypes = payload.data;
    });
    builder.addCase(fetchFuelTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFuelTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.fuelTypes = payload.data;
    });
    builder.addCase(fetchReportTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReportTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.reportTypes = payload.data;
    });
    builder.addCase(fetchOperationTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOperationTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.operationTypes = payload.data;
    });
    builder.addCase(fetchStocksList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStocksList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.stocksList = payload.data;
    });
    builder.addCase(fetchItemsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchItemsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.itemsList = payload.data;
    });
    builder.addCase(fetchItemTypesList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchItemTypesList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.itemTypesList = payload.data;
    });
    builder.addCase(fetchUnitTypesList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnitTypesList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.unitTypesList = payload.data;
    });
    builder.addCase(fetchModulesList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchModulesList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modulesList = payload.data;
    });
  },
});

export default selectorsSlice.reducer;

export const selectWorkers = (state) => state.selectors.workers;
export const selectPackers = (state) => state.selectors.packers;
export const selectProviders = (state) => state.selectors.providers;
export const selectCompanies = (state) => state.selectors.companies;
export const selectLocations = (state) => state.selectors.locations;
export const selectSupervisors = (state) => state.selectors.supervisors;
export const selectRegisters = (state) => state.selectors.registers;
export const selectHarvestTypes = (state) => state.selectors.harvestTypes;
export const selectWorkingMapTypes = (state) => state.selectors.workingMapTypes;
export const selectRoles = (state) => state.selectors.roles;
export const selectAbsenceTypes = (state) => state.selectors.absenceTypes;
export const selectLoading = (state) => state.selectors.isLoading;
export const selectBehaviorTypes = (state) => state.selectors.behaviorTypes;
export const selectDocumentTypes = (state) => state.selectors.documentTypes;
export const selectFuelTypes = (state) => state.selectors.fuelTypes;
export const selectReportTypes = (state) => state.selectors.reportTypes;
export const selectOperationTypes = (state) => state.selectors.operationTypes;
export const selectStocksList = (state) => state.selectors.stocksList;
export const selectItemsList = (state) => state.selectors.itemsList;
export const selectItemTypesList = (state) => state.selectors.itemTypesList;
export const selectUnitTypesList = (state) => state.selectors.unitTypesList;
export const selectModulesList = (state) => state.selectors.modulesList;
