// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, useTheme } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import Page from '../../components/Page';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Page title="Login">
      <Image
        visibleByDefault
        disabledEffect
        src="/assets/bg_login.jpg"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          'img': {
          filter: 'blur(4px)',
          transform: 'scale(1.1)',
          }
        }}
      />
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box sx={{ zIndex: 1, background: theme.palette.background.paper, padding: 3, borderRadius: '16px' }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {translate('signInToFarmUpPortal')}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{translate('enterYourDetailsBelow')}</Typography>
                </Box>
                <LanguagePopover />
              </Stack>
              <LoginForm />
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
