import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    workTypesParameters: [],
    workTypesParametersById: {},
    error: '',
};

export const fetchParametersWorkTypes = createAsyncThunk(
    'parametersWorkTypes/fetchParametersWorkTypes',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmaptype`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersWorkTypes = createAsyncThunk(
    'parametersWorkTypes/deleteParametersWorkTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/workingmaptype`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersWorkTypes = createAsyncThunk(
    'parametersWorkTypes/createParametersWorkTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/workingmaptype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersWorkTypesById = createAsyncThunk(
    'parametersWorkTypes/fetchParametersWorkTypesById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmaptype/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersWorkTypes = createAsyncThunk(
    'parametersWorkTypes/updateParametersWorkTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/workingmaptype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersWorkTypesSlice = createSlice({
    name: 'parametersWorkTypesSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersWorkTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersWorkTypes.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.workTypesParameters = payload.data;
        });
        builder.addCase(fetchParametersWorkTypes.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersWorkTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersWorkTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersWorkTypes.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersWorkTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersWorkTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersWorkTypes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersWorkTypesById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.workTypesParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersWorkTypesById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersWorkTypesById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersWorkTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersWorkTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersWorkTypes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersWorkTypesSlice.reducer;

export const selectParametersWorkTypesData = (state) => state.parametersWorkTypes.workTypesParameters;
export const selectParametersWorkTypesById = (state) => state.parametersWorkTypes.workTypesParametersById;
export const selectParametersWorkTypesLoading = (state) => state.parametersWorkTypes.isLoading;
export const selectParametersWorkTypesError = (state) => state.parametersWorkTypes.error;
