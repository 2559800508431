import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import TableRowCellsFromHeader from '../../../components/table/TableRowCellsFromHeader';

FertirrigationTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    headerData: PropTypes.array.isRequired,
    onRowDelete: PropTypes.func,
    canDelete: PropTypes.bool,
    selected: PropTypes.bool,
    onSelectRow: PropTypes.func,
    onView: PropTypes.func,
};

export default function FertirrigationTableRow({
    row,
    headerData,
    onRowDelete,
    canDelete,
    selected,
    onSelectRow,
    onView,
}) {
    const { translate } = useLocales();

    const handleDelete = () => {
        onRowDelete(row.id);
    };

    const handleRowSelect = () => {
        onSelectRow(row.id);
    };

    return (
        <TableRow hover>
            {onSelectRow &&
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={handleRowSelect} />
                </TableCell>
            }

            <TableRowCellsFromHeader headers={headerData} row={row} />

            {onView &&
                <TableCell align="center">
                    <IconButtonAnimate
                        sx={{ p: 0.5 }}
                        title={translate('view')}
                        onClick={onView}
                        size="small"
                    >
                        <Iconify icon="eva:search-fill" />
                    </IconButtonAnimate>
                </TableCell>
            }

            {canDelete && <TableCell align="center">
                <IconButtonAnimate
                    onClick={handleDelete}
                    sx={{
                        color: 'error.main',
                        p: 0.5,
                    }}
                    title={translate('delete')}
                    size="small"
                >
                    <Iconify icon="eva:trash-2-outline" />
                </IconButtonAnimate>
            </TableCell>}
        </TableRow>
    );
}
