import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    cropContainersParameters: [],
    cropContainersParametersById: {},
    error: '',
};

export const fetchParametersCropContainers = createAsyncThunk(
    'parametersCropContainers/fetchParametersCropContainers',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/packages`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersCropContainers = createAsyncThunk(
    'parametersCropContainers/deleteParametersCropContainers',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/packages`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersCropContainers = createAsyncThunk(
    'parametersCropContainers/createParametersCropContainers',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/packages`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersCropContainersById = createAsyncThunk(
    'parametersCropContainers/fetchParametersCropContainersById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/packages/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersCropContainers = createAsyncThunk(
    'parametersCropContainers/updateParametersCropContainers',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/packages`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersCropContainersSlice = createSlice({
    name: 'parametersCropContainersSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersCropContainers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCropContainers.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.cropContainersParameters = payload.data;
        });
        builder.addCase(fetchParametersCropContainers.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersCropContainers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersCropContainers.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersCropContainers.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersCropContainers.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersCropContainers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersCropContainers.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersCropContainersById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.cropContainersParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersCropContainersById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCropContainersById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersCropContainers.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersCropContainers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersCropContainers.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersCropContainersSlice.reducer;

export const selectParametersCropContainersData = (state) => state.parametersCropContainers.cropContainersParameters;
export const selectParametersCropContainersById = (state) => state.parametersCropContainers.cropContainersParametersById;
export const selectParametersCropContainersLoading = (state) => state.parametersCropContainers.isLoading;
export const selectParametersCropContainersError = (state) => state.parametersCropContainers.error;
