// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  harvest_entries: 'Harvest Entries',
  start_date: 'Start date',
  end_date: 'End date',
  search: 'Search...',
  filters: 'Filters',
  dashboard: 'Dashboard',
  harvest: 'Harvest',
  entries: 'Harvest Entries',
  scrap: 'Scrap',
  qualityControl: 'Quality Control',
  massMovement: 'Mass Movement',
  harvestVsWaste: 'Harvest vs Scrap',
  productivity: 'Picker Productivity',
  packaging: 'Packing',
  packagingSum: 'Productivity',
  workMaps: 'Work Maps',
  checklistReport: 'Checklist Report',
  workHours: 'Work Hours',
  fertirrigation: 'Fertirrigation',
  tanksList: 'Tank List',
  tanksHistory: 'Refill Report',
  humanResources: 'Human Resources',
  staff: 'Staff',
  staffDocuments: 'Staff Documents',
  workingNow: 'Working Now',
  inventoryManagement: 'Inventory Management',
  itemTypes: 'Item Types',
  items: 'Items',
  stocks: 'Warehouses',
  stocksMovements: 'Stocks Movements',
  stockByDay: 'Stock By Date',
  consumption: 'Consumption',
  electricity: 'Electricity',
  water: 'Water',
  fuel: 'Fuel',
  parameters: 'Parameters',
  bonus: 'Picker Bonus',
  locations: 'Sectors',
  culture: 'Culture',
  absenceTypes: 'Absence Types',
  harvestDefectTypes: 'Harvest Quality Control',
  performanceTypes: 'Performance Types',
  documentTypes: 'Documents',
  reportScheduler: 'Report Scheduler',
  day: 'Date',
  worker_id: 'ID',
  name: 'Name',
  location: 'Sector',
  yield: 'Container',
  quantity: 'Qty',
  hour: 'Time',
  weight_kg: 'Weight (Kg)',
  weight: 'Weight',
  provider: 'Company',
  supervisor: 'Supervisor',
  no_data: 'No Data',
  edit: 'Edit',
  delete: 'Delete',
  dense: 'Dense',
  rows_per_page: 'Rows per page',
  clear_all: 'Clear all',
  registered_by: 'Registered by',
  workers: 'Workers',
  providers: 'Companies',
  supervisors: 'Supervisors',
  cancel: 'Cancel',
  harvest_entries_edit: 'Edit Harvest entry',
  harvest_scrap_edit: 'Edit Scrap entry',
  harvest_quality_control_edit: 'Edit Quality Control entry',
  crop: 'Harvest',
  waste: 'Scrap',
  industrial_waste: 'Industrial',
  harvest_scrap: 'Harvest Scrap',
  harvest_type: 'Culture',
  harvest_scrap_create: 'Create Scrap entry?',
  create: 'Create',
  update_successful: 'Update Successful',
  successfully_created: 'Successfully created',
  harvest_vs_waste: 'Harvest vs Scrap',
  waste_percent: 'Scrap %',
  industrial: 'Industrial',
  industrial_percent: 'Industrial %',
  total_waste: 'Total Scrap',
  total_waste_percent: 'Total Scrap %',
  harvest_mass_movement: 'Mass Movement',
  harvest_productivity: 'Harvest Productivity',
  time_harvest: 'Worked (h)',
  speed: 'Speed (Kg/h)',
  speed_kg_h: 'Speed (Kg/h)',
  companies: 'Companies',
  company: 'Company',
  package: 'Container',
  worker: 'Worker',
  harvest_packaging: 'Harvest Container',
  start: 'Start',
  end: 'End',
  rest_time_min: 'Rest Time (min)',
  hours: 'Hours',
  language: 'Language',
  harvest_quality_control: 'Harvest Quality Control',
  defect: 'Defect',
  bonus_multiplier: 'Bonus Multiplier',
  done_by: 'Done by',
  notes: 'Notes',
  working_now: 'Working Now',
  document_id: 'Document Id',
  tax_id: 'Tax Id',
  social_security: 'Social Security',
  nationality: 'Nationality',
  birth_date: 'Birth Date',
  role: 'Role',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  gender: 'Gender',
  address: 'Address',
  phone: 'Phone',
  email: 'Email',
  work_type: 'Work Type',
  unclosed: 'Unclosed',
  work_maps: 'Work Maps',
  work_maps_edit: 'Edit Work Map',
  work_hours_edit: 'Edit Work Hours',
  work_hours: 'Work Hours',
  map_hours_edit: 'Edit Work Hours',
  map_time_in: 'Start Time',
  map_time_out: 'End Time',
  map_time_rest: 'Rest Time (min)',
  checklist_report: 'Checklist Report',
  settings: 'Settings',
  home: 'Home',
  profile: 'Profile',
  logout: 'Logout',
  light: 'Light',
  dark: 'Dark',
  lowCondition: '{{fieldName}} low ({{value}})',
  highCondition: '{{fieldName}} high ({{value}})',
  emptyCondiditon: '{{fieldName}} value is invalid',
  drainageRaisedTo: 'Drainage raised to ({{value}})',
  drainageLoweredTo: 'Drainage lowered to ({{value}})',
  tankAlert: 'Tank without refill for ({{value}}) days',
  EC: 'EC',
  pH: 'pH',
  percentOfDrainage: '% of drainage',
  percentOfHumidity: '% of humidity',
  temperature: 'Temperature',
  ECInAndECOut: 'EC in + EC out',
  readingOneHour: 'Reading 1 Hour',
  readingTwoHour: 'Reading 2 Hour',
  readingThreeHour: 'Reading 3 Hour',
  readingOnePercent: 'Reading 1 Percent',
  readingTwoPercent: 'Reading 2 Percent',
  readingThreePercent: 'Reading 3 Percent',
  dailyPercent: 'Daily Avarage',
  list: 'Reading',
  cumulative: 'Cumulative',
  drainage: 'Drainage',
  valve: 'Valve',
  inPH: '[In] pH',
  outPH: '[Out] pH',
  inEC: '[In] EC',
  outEC: '[Out] EC',
  inVolume: '[In] Volume',
  inTotalVolume: '[In] Total Volume',
  outVolume: '[Out] Volume',
  percentage: 'Drainage %',
  updatedAt: 'Updated at',
  updatedBy: 'Updated by',
  createdAt: 'Created at',
  action: 'Action',
  fertirrigationDrainage: 'Fertirrigation Drainage',
  message: 'Alert',
  solved: 'Solved',
  yes: 'Yes',
  no: 'No',
  substrate: 'Substrate',
  humidityPercent: 'Humidity %',
  ECmilliSiemensPerCentimeter: 'EC mS/cm',
  humiditySensorial: 'Humidity Sensorial',
  roots: 'Roots',
  tanks: 'Tanks',
  view: 'View',
  itemType: 'Item Type',
  tank: 'Tank',
  totalVolume: 'Capacity',
  stock: 'Main Warehouse',
  refillHistory: 'Refill History',
  doneBy: 'Done By',
  refillItems: 'Refill Items',
  additionalStock: 'Secondary Warehouse',
  totalItems: 'Total Items',
  date: 'Date',
  volume: 'Volume',
  resolutionDay: 'Resolution Date',
  reading1: 'Reading 1',
  reading2: 'Reading 2',
  reading3: 'Reading 3',
  percent: 'Percent',
  alerts: 'Alerts',
  information: 'Information',
  back: 'Back',
  absenceBook: 'Absence Book',
  absenceBookEdit: 'Absence Book Edit',
  absenceType: 'Absence Type',
  durationInDays: 'Duration (days)',
  all: 'All',
  fieldIsRequired: 'Field is required',
  workerFeedback: 'Worker Feedback',
  timesheets: 'Timesheets',
  payroll: 'Payroll',
  absenceBookCreate: 'Absence Book Create',
  timeOther: 'Time Other',
  timeHarvest: 'Time Harvest',
  kg: 'Kg',
  kgHour: 'Kg/h',
  multiplier: 'Multiplier',
  showTotals: 'Show Totals',
  behavior: 'Behavior',
  behaviorType: 'Behavior Type',
  workerFeedbackCreate: 'Worker Feedback Create',
  workerFeedbackEdit: 'Worker Feedback Edit',
  theValueShouldBeANumber: 'The value should be a number',
  taxID: 'Tax ID',
  idNumber: 'ID number',
  socialSecurityNumber: 'Social Security Number',
  typeOfContract: 'Type of contract',
  salary: 'Salary',
  staffEdit: 'Edit Worker data',
  staffCreate: 'Insert New Worker',
  general: 'Main info',
  legalInfo: 'Legal Info',
  contract: 'Contract Details',
  contractType: 'Contract Type',
  id: 'ID',
  active: 'Active',
  editInfo: 'Entry details',
  activateStaff: 'Activate Worker(s)',
  inactivateStaff: 'Inactivate Worker(s)',
  activateSingleStaffMember: 'Are you sure you want to activate this worker?',
  activateMultipleStaffMembers: 'Are you sure you want to activate these workers?',
  inactivateSingleStaffMember: 'Are you sure you want to inactivate this worker?',
  inactivateMultipleStaffMembers: 'Are you sure you want to inactivate these workers?',
  activate: 'Activate',
  inactivate: 'Inactivate',
  changeStaffActiveStatusSuccess: '{{numberOfWorkers}} worker(s) status updated.',
  documentType: 'Document Type',
  expiryDate: 'Expires at',
  filename: 'Filename',
  editDocument: 'Edit Document',
  addDocument: 'Add Document',
  link: 'Link',
  invalidLinkFormat: 'Invalid link format',
  upload: 'Upload',
  download: 'Download',
  activityTimeReport: 'Activity Report',
  missingMandatoryDocs: 'Missing mandatory docs',
  expiresInDays: 'Expires at',
  workType: 'Work Type',
  total: 'Total',
  field: 'Farm',
  low: 'Low',
  high: 'High',
  normal: 'Normal',
  consumptionElectricityCreate: 'Insert Eletricity Reading',
  consumptionElectricityEdit: 'Edit Eletricity Reading',
  consumptionWaterEdit: 'Edit Water Reading',
  consumptionWaterCreate: 'Insert Water Reading',
  fileIsNotFound: 'File is not found',
  reading: 'Reading',
  fuelType: 'Fuel Type',
  consumptionFuelEdit: 'Edit Fuel Reading',
  consumptionFuelCreate: 'Insert Fuel Reading',
  documents: 'Documents',
  fileTooBig: 'File is too big, max size is 10MB',
  harvestMassMovement: 'Harvest Mass Movement',
  absenceParameters: 'Types of Absence',
  pickingBonus: 'Picking Bonus',
  abbreviation: 'Abbreviation',
  editAbsenceParameters: 'Edit Absence Type',
  createAbsenceParameters: 'Create Absence Type',
  description: 'Description',
  type: 'Type',
  bonusMultiplier: 'Bonus Multiplier',
  positive: 'Positive',
  negative: 'Negative',
  createWorkerFeedbackParameters: 'Create Type of Feedback',
  editWorkerFeedbackParameters: 'Edit Type of Feedback',
  fromKgHour: 'From (Kg/h)',
  toKgHour: 'To (Kg/h)',
  bonusDollars: 'Bonus ($)',
  createPickingBonusParameters: 'Create Picking Bonus Parameter',
  editPickingBonusParameters: 'Edit Picking Bonus Parameter',
  deleteDocumentTypesParameters: 'Delete Document Types Parameter',
  createDocumentTypesParameters: 'Create Document Type',
  editDocumentTypesParameters: 'Edit Document Type',
  document: 'Document',
  mandatory: 'Mandatory',
  expiration: 'Expiration',
  farm: 'Farm Information',
  cropContainers: 'Packaging',
  cropQualityControl: 'Harvest Quality Control',
  createCropParameters: 'Create Culture',
  harvestBonusPenalty: 'Bonus penalty (Multiplier)',
  cropQualityControlParameters: 'Harvest Quality Control',
  createCropQualityControlParameters: 'Create Defect Type',
  editCropQualityControlParameters: 'Edit Defect type',
  downloadLimitReached: 'Download limit reached, please select less than {{limit}} files',
  linkIsTooLong: 'Link is too long, max length is 100 characters',
  deleteCropContainersParameters: 'Delete Container',
  containerName: 'Container Name',
  capacityGr: 'Capacity (gr)',
  editCropContainersParameters: 'Edit Container',
  createCropContainersParameters: 'Create Container',
  createLocationsParameters: 'Create Location',
  editLocationsParameters: 'Edit Location',
  irrigatorsPerVase: 'Irrigators per vase',
  valveSubsector: 'Valve/Subsector',
  sector: 'Sector',
  locationsParameters: 'Locations Parameters',
  cropContainersParameters: 'Container Parameters',
  reporting: 'Reporting',
  reportName: 'Report Name',
  frequency: 'Frequency',
  schedule: 'Schedule',
  sendTo: 'Send To',
  ccTo: 'CC to',
  createReportingParameters: 'Create Reporting Scheduler',
  editReportingParameters: 'Edit Reporting Scheduler',
  cropParameters: 'Harvest Parameters',
  stockName: 'Warehouse',
  inventoryStocks: 'Inventory Stocks',
  stockMovements: 'Stock Movements',
  stockCreate: 'Create Warehouse',
  stockMin: 'Minimum Stock level',
  unitsInStock: 'Units in Stock',
  totalQuantityInStock: 'Qty. in Stock',
  level: 'Stock Level',
  item: 'Item',
  operation: 'Operation',
  sourceStock: 'Source',
  destinyStock: 'Destiny',
  affectedQuantity: 'Operated Qty',
  initialQuantity: 'Initial Qty',
  finalQuantity: 'Final Qty',
  recentMovements: 'Recent Operations',
  operationTypes: 'Operation Types',
  itemTypesList: 'Article Types',
  stocksList: 'Warehouse List',
  itemsList: 'Article List',
  unitType: 'Unit type',
  unitQuantity: 'Capacity of the package',
  unitInStock: 'Unit in stock',
  quantityInStock: 'Quantity in stock',
  inventoryItems: 'Inventory Items',
  itemCreate: 'Create Article',
  itemEdit: 'Item Edit',
  itemTypeCreate: 'Item Type Create',
  itemTypeEdit: 'Item Type Edit',
  inventoryTypes: 'Inventory Types',
  access: 'Access',
  manageAccess: 'Manage access',
  addAccess: 'Add access',
  password: 'Password',
  userName: 'Username',
  onboardingLanguage: 'Onboarding language',
  portal: 'Portal',
  app: 'App',
  both: 'Both',
  portuguese: 'Portuguese',
  english: 'English',
  spanish: 'Spanish',
  passwordReset: 'Password Reset',
  usernameIsTooLong: 'Maximum 100 characters',
  usernameIsTooShort: 'Minimum 8 characters',
  passwordIsTooLong: 'Maximum 100 characters',
  passwordIsTooShort: 'Minimum 6 characters',
  invalidPasswordFormat: 'Invalid password format',
  deleteAccess: 'Delete Access',
  resetPassword: 'Reset Password',
  deleteAccessText: 'Delete access to the worker?',
  viewStock: 'View Warehouse',
  removeStockItemsBeforeDeletion: 'Warehouse must be empty before deletion',
  totalHarvest: 'Total Harvest',
  totalPackages: 'Total Packages',
  productionSpeed: 'Picking Speed',
  pickingPerformance: 'Picking Productivity',
  industrialWaste: 'Industrial',
  pickedInKg: 'Picked (Kg)',
  workedInHours: 'Worked (h)',
  speedInKgHour: 'Speed (Kg/h)',
  previousDay: 'vs previous day',
  vsPreviousDay: 'vs previous day',
  harvestSlashWaste: 'Harvest / Scrap',
  drainageAlert: 'Fertirrigation Alerts',
  notSolved: 'Not Solved',
  at: 'at',
  timeSpentOnTasks: 'Time spent on tasks',
  totalOfHoursSpentOnEachTask: 'Total spent on each task',
  unclosedWorkMaps: 'Unclosed Work Maps',
  workMapsNeedClosing: '{{workMapsQuantity}} Work maps need closing',
  viewAll: 'View All',
  allMapsAreClosedForThisDay: 'All maps are closed for this day',
  accessManagement: 'Access Management',
  portalPermissions: 'Portal Permissions',
  roleList: 'Role',
  moduleList: 'Module',
  canView: 'Can view',
  canEdit: 'Can edit',
  canCreate: 'Can create',
  canDelete: 'Can delete',
  menu: 'Menu',
  operations: 'Operations',
  changesAreNotSaved: 'All changes will be lost. Do you want to proceed?',
  passwordHint: 'Minimum six characters at least one letter and one number',
  logins: 'Logins',
  loginEdit: 'Login Edit',
  loginCreate: 'Login Create',
  signInToFarmUpPortal: 'Sign in to FarmUp Portal',
  university: 'University',
  website: 'Website',
  whatsApp: 'WhatsApp',
  phoneContact: 'Phone Contact',
  enterYourDetailsBelow: 'Enter your details below',
  login: 'Login',
  loginIsRequired: 'Login is required',
  passwordIsRequired: 'Password is required',
  incorrectCredentials: 'Incorrect Credentials',
  deletePopupTitle: 'Delete',
  deletePopupMessageOneItem: 'Are you sure want to delete this item?',
  deletePopupMessageMultipleItems: 'Are you sure want to delete these items?',
  banned: 'Inactive',
  selectDateRange: 'Select Date Range',
  endDateMustBeLaterThanStartDate: 'End date must be later than start date',
  maximumRangeCannotBeMoreThanOneYear: 'Maximum range cannot be more than one year',
  inactive: 'Inactive',
  status: 'Status',
  sorryPageNotFound: 'Sorry page not found!',
  pageNotFound: '404 Page Not Found',
  couldNotFindThePageYouAreLookingFor: 'Sorry we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  goToHome: 'Go to Home',
  showInactiveWorkers: 'Show inactive workers',
  withAccess: 'With access',
  inactiveUsers: 'Inactive users',
  searchInHelp: 'Search in help...',
  everyMonth: 'every month',
  everyMonthDaysShort: 'day of the month',
  everyWeekDaysShort: 'day of the week',
  everyHours: 'every hour',
  everyMinutes: 'every minute',
  everyMinutesForHourPeriod: 'every',
  yearOption: 'year',
  monthOption: 'month',
  weekOption: 'week',
  dayOption: 'day',
  hourOption: 'hour',
  minuteOption: 'minute',
  rebootOption: 'reboot',
  prefixPeriod: 'Every',
  prefixMonths: 'in',
  prefixMonthDays: 'on',
  prefixWeekDays: 'on',
  prefixWeekDaysForMonthAndYearPeriod: 'and',
  prefixHours: 'at',
  prefixMinutesForHourPeriod: 'at',
  suffixMinutesForHourPeriod: 'minute(s)',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  sundayShort: 'SUN',
  mondayShort: 'MON',
  tuesdayShort: 'TUE',
  wednesdayShort: 'WED',
  thursdayShort: 'THU',
  fridayShort: 'FRI',
  saturdayShort: 'SAT',
  januaryShort: 'JAN',
  februaryShort: 'FEB',
  marchShort: 'MAR',
  aprilShort: 'APR',
  mayShort: 'MAY',
  juneShort: 'JUN',
  julyShort: 'JUL',
  augustShort: 'AUG',
  septemberShort: 'SEP',
  octoberShort: 'OCT',
  novemberShort: 'NOV',
  decemberShort: 'DEC',
  recordsWithTheSameWorkerIdAreNotAllowed: 'Records with the same worker ID are not allowed',
  recordsWithTheSameFiscalNumberAreNotAllowed: 'Records with the same fiscal number are not allowed',
  recordsWithTheSameFilenameAreNotAllowed: 'Records with the same filename are not allowed',
  recordsWithTheSameNameAreNotAllowed: 'Records with the same name are not allowed',
  expirationDateIsRequiredForThisTypeOfDocument: 'Expiration date is required for this type of document',
  somethingWentWrongWhileCreatingStaffMember: 'Something went wrong while creating worker',
  recordsWithTheSameGroupAreNotAllowed: 'Records with the same group are not allowed',
  workerHasAlreadyTheAccess: 'Worker has already the access',
  recordsWithTheSameUsernameAreNotAllowed: 'Records with the same username are not allowed',
  somethingWentWrongWhileFetchingTheRequest: 'Something went wrong while fetching the request',
  outOfStock: 'Out Of Stock',
  lowStock: 'Low Stock',
  inStock: 'In Stock',
  apply: 'Apply',
  editCropParameters: 'Edit Crop Parameters',
  formulation: 'Formulation',
  uploadFiles: 'Upload File',
  maximumOneFileToUpload: 'Maximum one file to upload',
  downloadTemplate: 'Download Template',
  ok: 'OK',
  numberOfWorkersImportedSuccessfully: '{{numberOfWorkers}} workers imported successfully!',
  numberOfWorkersWereInsertedSuccessfully: '{{numberOfWorkers}} workers were inserted successfully!',
  wrong_workerid: 'Worker ID is missing or contains non numeric characters',
  already_exists: 'Worker ID already exists',
  missing_field_name: 'Worker name is missing',
  missing_field_company: 'Company is missing',
  missing_field_role: 'Role is missing ',
  wrong_company: 'Company not found',
  wrong_role: 'Role not found',
  wrong_social_security: 'Social Security must be 11 numeric characters',
  wrong_birth_date: 'Birth date format must be DD/MM/YYYY',
  wrong_contract_date: 'Contract start date format must be DD/MM/YYYY',
  wrong_salary: 'Salary must be numeric characters only',
  lines: 'Lines',
  members: 'Workers',
  types: 'Types',
  editCompany: 'Edit Company',
  createCompany: 'Create Company',
  harvestKg: 'Harvest (Kg)',
  save: 'Save',
  selected: 'selected',
  workerHours: 'Worker Hours',
  mapHours: 'Map Hours',
  workerId: 'Worker ID',
  permissions: 'Permissions',
  valueMustBeAnInteger: 'Value must be an integer',
  workTypes: 'Work Types',
  workTypesParameters: 'Work Types Parameters',
  editWorkTypesParameters: 'Edit Work Type',
  createWorkTypesParameters: 'Create Work Type',
  daysOffPlanning: 'Day off Planner',
  monthPicker: 'Month',
  thisGapShouldBeChangedInTheAbsenceBookSection: 'This Day Off must be changed in the Absence Book',
  dropOrSelectFile: 'Drop or Select file',
  dropFilesHereOrClickBrowseThroughYourMachine: 'Drop file here or click <1>browse</1> through your machine',
  harvestPackagingSummary: 'Harvest Packaging Summary',
  without_errors: 'Without errors',
  pleaseFixErrorsAndReuploadFile: 'Please fix errors and reupload file',
  continue: 'Continue',
  wrongUsernameOrPassword: 'Wrong username or password',
  accountHasNoAccessToTheApplication: 'Your account has no permissions to use this application',
  details: 'Details',
  stockEdit: 'Edit Warehouse',
  harvestProductivityListTab: 'List',
  harvestProductivitySummaryTab: 'Summary',
  harvestProductivityPickerBonusTooltip: 'How is this calculated? (Bonus map 1 + Bonus map 2 + …. ) x Multiplier',
  reportDescription: 'Description',
  sendNow: 'Send Now',
  reportRequestSent: 'Report request sent, results will arrive soon to the configured email',
  wrongDateFormat: 'Wrong date format',
};

export default en;
