import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useLocales from '../../../hooks/useLocales';
import {
  deleteParametersLocations,
  fetchParametersLocations,
} from '../../../redux/slices/parametersLocationsSlice';

ParametersLocationsDeleteModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function ParametersLocationsDeleteModal({ passedData, open, handleClose }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const handleDelete = () => {
    dispatch(deleteParametersLocations({ device: window.navigator.userAgent, ids: passedData })).then(() => {
      dispatch(fetchParametersLocations());
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="delete-parameters-locations">
      <DialogTitle id="delete-parameters-locations">
        {translate('deletePopupTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('deletePopupMessageOneItem')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
