import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import useLocales from '../../../hooks/useLocales';
import IMStockItemsTable from '../list/IMStockItemsTable';
import {
    fetchIMStockItemsById,
    selectIMStockItemsById,
    selectIMStocksLoading,
} from '../../../redux/slices/imStocksSlice';

IMStockItemsModal.propTypes = {
    passedData: PropTypes.number,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default function IMStockItemsModal({ open, handleClose, passedData }) {
    const { translate } = useLocales();
    const dispatch = useDispatch();

    const stockItems = useSelector(selectIMStockItemsById);
    const isLoading = useSelector(selectIMStocksLoading);

    useEffect(() => {
        dispatch(fetchIMStockItemsById(passedData));
    }, [dispatch, passedData]);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="stock-items" maxWidth="md" fullWidth>
            <DialogTitle id="stock-items">
                {translate('items')}
            </DialogTitle>
            <DialogContent>
                <IMStockItemsTable isLoading={isLoading} tableDataList={stockItems} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
                    {translate('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
