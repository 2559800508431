import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    reportingParameters: [],
    reportingParametersById: {},
    error: '',
};

export const fetchParametersReporting = createAsyncThunk(
    'parametersReporting/fetchParametersReporting',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/dispatcher`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersReporting = createAsyncThunk(
    'parametersReporting/deleteParametersReporting',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/dispatcher`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const sendNowParametersReporting = createAsyncThunk(
    'parametersReporting/sendNowParametersReporting',
    async ({ apiMethod, database, emailTo, emailCc }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/${apiMethod}?database=${database}&emailto=${emailTo}&emailcc=${emailCc}`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersReporting = createAsyncThunk(
    'parametersReporting/createParametersReporting',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/dispatcher`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersReportingById = createAsyncThunk(
    'parametersReporting/fetchParametersReportingById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/dispatcher/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersReporting = createAsyncThunk(
    'parametersReporting/updateParametersReporting',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/dispatcher`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersReportingSlice = createSlice({
    name: 'parametersReportingSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersReporting.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersReporting.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.reportingParameters = payload.data;
        });
        builder.addCase(fetchParametersReporting.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersReporting.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersReporting.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersReporting.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(sendNowParametersReporting.fulfilled, (state) => {
            state.error = '';
        });
        builder.addCase(sendNowParametersReporting.rejected, (state, action) => {
            state.error = action.payload;
        });
        builder.addCase(createParametersReporting.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersReporting.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersReporting.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersReportingById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.reportingParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersReportingById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersReportingById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersReporting.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersReporting.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersReporting.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersReportingSlice.reducer;

export const selectParametersReportingData = (state) => state.parametersReporting.reportingParameters;
export const selectParametersReportingById = (state) => state.parametersReporting.reportingParametersById;
export const selectParametersReportingLoading = (state) => state.parametersReporting.isLoading;
export const selectParametersReportingError = (state) => state.parametersReporting.error;
