import React from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";
import { Link, TableCell } from "@mui/material";
import { numberByLocale } from "../../utils/misc";
import useLocales from "../../hooks/useLocales";

TableRowCellsFromHeader.propTypes = {
    headers: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired,
};

export default function TableRowCellsFromHeader({ headers, row }) {
    const { currentLang } = useLocales();

    return (
        <>
            {
                headers.map((column) => {
                    if (!column.show || column.label === 'action') return null;

                    const rowValue = row[column.id];
                    let columnValue;

                    if (!rowValue) {
                        columnValue = null;
                    } else if (!Number.isNaN(+rowValue) && typeof +rowValue === 'number') {
                        columnValue = numberByLocale(rowValue, currentLang.locale);
                    } else {
                        columnValue = rowValue;
                    }

                    return <TableCell
                        key={_uniqueId()}
                        align={column.align}
                    >
                        {column.isLink
                            ? <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    maxWidth: '260px',
                                    display: 'inline-block',
                                    wordWrap: 'break-word',
                                }}
                                href={columnValue}
                            >
                                {columnValue}
                            </Link>
                            : columnValue
                        }
                    </TableCell>
                })
            }
        </>
    )
}