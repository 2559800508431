import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import ExportTable from '../../../components/ExportTable';
import SearchInput from '../../../components/SearchInput';

IMTableToolbar.propTypes = {
    headerData: PropTypes.array,
    searchString: PropTypes.string,
    onSearch: PropTypes.func,
    tableData: PropTypes.array,
    exportFileTitle: PropTypes.string,
};

export default function IMTableToolbar({
    headerData,
    searchString,
    onSearch,
    tableData,
    exportFileTitle,
}) {
    const handleSearch = (e) => {
        onSearch(e.target.value);
    };

    return (
        <Stack
            alignItems={{ xs: 'flex-end', md: 'center' }}
            justifyContent="flex-end"
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ py: 2.5, px: 3 }}
        >
            <SearchInput handleSearch={handleSearch} searchString={searchString} />
            <Stack direction="row" alignItems="center" justifyContent="center">
                <ExportTable
                    tableData={tableData}
                    tableHeaders={headerData.filter(header => header.label !== 'action')}
                    exportFileTitle={exportFileTitle}
                />
            </Stack>
        </Stack>
    );
}
