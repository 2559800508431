import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import useLocales from '../hooks/useLocales';
import Iconify from './Iconify';

SearchInput.propTypes = {
    searchString: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
};

function SearchInput({ searchString, handleSearch }) {
    const { translate } = useLocales();

    return (
        <TextField
            fullWidth
            value={searchString}
            onChange={handleSearch}
            placeholder={translate('search')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default SearchInput;