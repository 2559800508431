import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  editedWorkHours: null,
  workersData: [],
  filters: {
    startdate: null,
    enddate: null,
    supervisorid: '',
    locationid: '',
    worktypeid: '',
    companyid: '',
    userid: '',
    unclosed: '',
    search: '',
  },
  error: '',
};

export const fetchWorkHours = createAsyncThunk(
  'operationsWorkHours/fetchWorkHours',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchWorkHoursSingle = createAsyncThunk(
  'operationsWorkHours/fetchWorkHoursSingle',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers/${id}`);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const updateWorkHoursSingle = createAsyncThunk(
  'operationsWorkHours/updateWorkHoursSingle',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers`, { ...data });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteWorkHours = createAsyncThunk(
  'operationsWorkHours/deleteWorkHours',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers`, {
        data: {
          ids: data.data,
          device: data.device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const operationsWorkHoursSlice = createSlice({
  name: 'operationsWorkHours',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        supervisorid: '',
        locationid: '',
        worktypeid: '',
        companyid: '',
        userid: '',
        unclosed: '',
        search: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWorkHours.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkHours.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchWorkHours.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchWorkHoursSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkHoursSingle.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.editedWorkHours = payload.data;
    });
    builder.addCase(fetchWorkHoursSingle.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteWorkHours.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWorkHours.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteWorkHours.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = operationsWorkHoursSlice.actions;

export default operationsWorkHoursSlice.reducer;

export const selectOperationsWorkHoursFilters = (state) => state.operationsWorkHours.filters;
export const selectOperationsWorkHoursData = (state) => state.operationsWorkHours.data;
export const selectOperationsWorkHoursLoading = (state) => state.operationsWorkHours.isLoading;
export const selectOperationsWorkHoursError = (state) => state.operationsWorkHours.error;
export const selectOperationsEditedWorkHours = (state) => state.operationsWorkHours.editedWorkHours;
export const selectOperationsWorkHoursWorkers = (state) => state.operationsWorkHours.workersData;
