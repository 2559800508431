import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  loadingCount: 0,
  totalAnalytics: {
    totalPickedToday: 0,
    totalPickedYesterday: 0,
    totalPickedIndicator: 0,
    totalPickersToday: 0,
    totalPickersYesterday: 0,
    totalPickersIndicator: 0,
    totalPerformanceToday: 0,
    totalPerformanceYesterday: 0,
    totalPerformanceIndicator: 0,
    totalWasteToday: 0,
    totalIndWasteToday: 0,
    pickers: []
  },
  drainageAnalytics: {
    list: [],
    alerts: [],
  },
  activityTimeAnalytics: [],
  unclosedWorkMaps: [],
  filters: {
    date: null,
    productid: null,
    locationid: '',
  },
  error: '',
};

export const fetchDashboardTotalAnalytics = createAsyncThunk(
  'dashboardAnalytics/fetchDashboardTotalAnalytics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers/dashboard`, {
        params,
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchDashboardDrainageAnalytics = createAsyncThunk(
  'dashboardAnalytics/fetchDashboardDrainageAnalytics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/drainage/dashboard`, {
        params,
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchDashboardActivityTimeAnalytics = createAsyncThunk(
  'dashboardAnalytics/fetchDashboardActivityTimeAnalytics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers/activitytimereport`, {
        params: {
          startdate: params.date,
          enddate: params.date,
          productid: params.productid,
          locationid: params.locationid,
        }
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);


export const fetchDashboardUnclosedWorkMapsAnalytics = createAsyncThunk(
  'dashboardAnalytics/fetchDashboardUnclosedWorkMapsAnalytics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmap`, {
        params: {
          startdate: params.date,
          enddate: params.date,
          productid: params.productid,
          locationid: params.locationid,
          unclosed: 1,
        }
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const dashboardAnalyticsSlice = createSlice({
  name: 'dashboardAnalyticsSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        productid: null,
        locationid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDashboardTotalAnalytics.pending, (state) => {
      state.loadingCount += 1;
    });
    builder.addCase(fetchDashboardTotalAnalytics.fulfilled, (state, { payload }) => {
      state.loadingCount -= 1;
      state.error = '';
      state.totalAnalytics = payload.data;
    });
    builder.addCase(fetchDashboardTotalAnalytics.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingCount -= 1;
    });
    builder.addCase(fetchDashboardDrainageAnalytics.pending, (state) => {
      state.loadingCount += 1;
    });
    builder.addCase(fetchDashboardDrainageAnalytics.fulfilled, (state, { payload }) => {
      state.loadingCount -= 1;
      state.error = '';
      state.drainageAnalytics = payload.data;
    });
    builder.addCase(fetchDashboardDrainageAnalytics.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingCount -= 1;
    });
    builder.addCase(fetchDashboardActivityTimeAnalytics.pending, (state) => {
      state.loadingCount += 1;
    });
    builder.addCase(fetchDashboardActivityTimeAnalytics.fulfilled, (state, { payload }) => {
      state.loadingCount -= 1;
      state.error = '';
      state.activityTimeAnalytics = payload.data;
    });
    builder.addCase(fetchDashboardActivityTimeAnalytics.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingCount -= 1;
    });
    builder.addCase(fetchDashboardUnclosedWorkMapsAnalytics.pending, (state) => {
      state.loadingCount += 1;
    });
    builder.addCase(fetchDashboardUnclosedWorkMapsAnalytics.fulfilled, (state, { payload }) => {
      state.loadingCount -= 1;
      state.error = '';
      state.unclosedWorkMaps = payload.data;
    });
    builder.addCase(fetchDashboardUnclosedWorkMapsAnalytics.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingCount -= 1;
    });
  },
});

export const { setFilters, resetFilters } = dashboardAnalyticsSlice.actions;

export default dashboardAnalyticsSlice.reducer;

export const selectDashboardTotalAnalyticsFilters = (state) => state.dashboardAnalytics.filters;
export const selectDashboardTotalAnalyticsData = (state) => state.dashboardAnalytics.totalAnalytics;
export const selectDashboardDrainageAnalyticsData = (state) => state.dashboardAnalytics.drainageAnalytics;
export const selectDashboardUnclosedWorkMapsAnalyticsData = (state) => state.dashboardAnalytics.unclosedWorkMaps;
export const selectDashboardActivityTimeAnalyticsData = (state) => state.dashboardAnalytics.activityTimeAnalytics;
export const selectDashboardTotalAnalyticsLoading = (state) => state.dashboardAnalytics.loadingCount;
export const selectDashboardTotalAnalyticsError = (state) => state.dashboardAnalytics.error;
