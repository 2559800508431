import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  absenceBooks: [],
  absenceBook: null,
  filters: {
    startdate: null,
    enddate: null,
    userid: null,
    companyid: null,
    supervisorid: null,
    typeid: null,
  },
  error: '',
};

export const fetchHRAbsenceBooks = createAsyncThunk(
  'hrAbsenceBook/fetchHRAbsenceBooks',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/wfm`, {
        params,
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchHRAbsenceBook = createAsyncThunk(
  'hrAbsenceBook/fetchHRAbsenceBook',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/wfm/${id}`);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteHRAbsenceBook = createAsyncThunk(
  'hrAbsenceBook/deleteHRAbsenceBook',
  async ({ device, data }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/wfm`, {
        data: {
          ids: data,
          device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);


export const updateHRAbsenceBook = createAsyncThunk(
  'hrAbsenceBook/updateHRAbsenceBook',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/wfm`, data);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const createHRAbsenceBook = createAsyncThunk(
  'hrAbsenceBook/createHRAbsenceBook',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/wfm`, data);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const hrAbsenceBookSlice = createSlice({
  name: 'hrAbsenceBookSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        userid: null,
        companyid: null,
        supervisorid: null,
        typeid: null,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHRAbsenceBooks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHRAbsenceBooks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.absenceBooks = payload.data;
    });
    builder.addCase(fetchHRAbsenceBooks.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchHRAbsenceBook.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHRAbsenceBook.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.absenceBook = payload.data;
    });
    builder.addCase(fetchHRAbsenceBook.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteHRAbsenceBook.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHRAbsenceBook.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteHRAbsenceBook.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createHRAbsenceBook.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(createHRAbsenceBook.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createHRAbsenceBook.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateHRAbsenceBook.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(updateHRAbsenceBook.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateHRAbsenceBook.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setFilters, resetFilters } = hrAbsenceBookSlice.actions;

export default hrAbsenceBookSlice.reducer;

export const selectHRAbsenceBookFilters = (state) => state.hrAbsenceBook.filters;
export const selectHRAbsenceBooksData = (state) => state.hrAbsenceBook.absenceBooks;
export const selectHRAbsenceBookData = (state) => state.hrAbsenceBook.absenceBook;
export const selectHRAbsenceBookLoading = (state) => state.hrAbsenceBook.isLoading;
export const selectHRAbsenceBookError = (state) => state.hrAbsenceBook.error;
