import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import IMStockMovementsTableFilterSidebar from './IMStockMovementsTableFilterSidebar';
import ExportTable from '../../../components/ExportTable';
import SearchInput from '../../../components/SearchInput';
import DateRangePicker from '../../../components/date-range-picker';

import { resetFilters } from '../../../redux/slices/imStockMovementsSlice';

IMStockMovementsTableToolbar.propTypes = {
    searchString: PropTypes.string,
    onSearch: PropTypes.func,
    tableData: PropTypes.array,
    tableHeaders: PropTypes.array,
    exportFileTitle: PropTypes.string,
    onFilterEndDate: PropTypes.func,
    onFilterStartDate: PropTypes.func,
    filterStartDate: PropTypes.instanceOf(Date),
    filterEndDate: PropTypes.instanceOf(Date),
};

export default function IMStockMovementsTableToolbar({
    searchString,
    onSearch,
    tableData,
    tableHeaders,
    exportFileTitle,
    onFilterEndDate,
    onFilterStartDate,
    filterStartDate,
    filterEndDate,
}) {
    const dispatch = useDispatch();

    const [openFilter, setOpenFilter] = useState(false);

    const handleSearch = (e) => {
        onSearch(e.target.value);
    };
    const handleResetFilter = () => {
        dispatch(resetFilters());
        handleCloseFilter();
    };
    const handleCloseFilter = () => {
        setOpenFilter(false);
    };
    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    return (
        <Stack
            alignItems={{ xs: 'flex-end', md: 'center' }}
            justifyContent="flex-end"
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            sx={{ py: 2.5, px: 3 }}
        >
            {onFilterStartDate && onFilterEndDate && filterStartDate && filterEndDate && (
                <DateRangePicker
                    filterStartDate={filterStartDate}
                    filterEndDate={filterEndDate}
                    onFilterStartDate={onFilterStartDate}
                    onFilterEndDate={onFilterEndDate}
                />
            )}
            <SearchInput handleSearch={handleSearch} searchString={searchString} />
            <Stack direction="row" alignItems="center" justifyContent="center">
                <ExportTable tableData={tableData} tableHeaders={tableHeaders} exportFileTitle={exportFileTitle} />
                <IMStockMovementsTableFilterSidebar
                    onResetAll={handleResetFilter}
                    isOpen={openFilter}
                    onOpen={handleOpenFilter}
                    onClose={handleCloseFilter}
                />
            </Stack>
        </Stack>
    );
}
