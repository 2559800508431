import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    imStocks: [],
    imStockById: {},
    imStockItemsById: [],
    imStockMovementsById: [],
    error: '',
};

export const fetchIMStocks = createAsyncThunk(
    'imStocks/fetchIMStocks',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/stock`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteIMStocks = createAsyncThunk(
    'imStocks/deleteIMStocks',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/stock`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createIMStocks = createAsyncThunk(
    'imStocks/createIMStocks',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/stock`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMStockById = createAsyncThunk(
    'imStocks/fetchIMStockById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/stock/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMStockItemsById = createAsyncThunk(
    'imStocks/fetchIMStockItemsById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/stockarticle?stockid=${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMStockMovementsById = createAsyncThunk(
    'imStocks/fetchIMStockMovementsById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_API_KEY}/operationHistory/recent-operations?stockid=${id}`
            );

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateIMStocks = createAsyncThunk(
    'imStocks/updateIMStocks',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/stock`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const IMStocksSlice = createSlice({
    name: 'imStocksSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchIMStocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMStocks.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.imStocks = payload.data;
        });
        builder.addCase(fetchIMStocks.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteIMStocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteIMStocks.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteIMStocks.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createIMStocks.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createIMStocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createIMStocks.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchIMStockById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imStockById = payload;
            state.error = '';
        });
        builder.addCase(fetchIMStockById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMStockById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchIMStockItemsById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imStockItemsById = payload;
            state.error = '';
        });
        builder.addCase(fetchIMStockItemsById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMStockItemsById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchIMStockMovementsById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imStockMovementsById = payload;
            state.error = '';
        });
        builder.addCase(fetchIMStockMovementsById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMStockMovementsById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateIMStocks.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateIMStocks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateIMStocks.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default IMStocksSlice.reducer;

export const selectIMStocksData = (state) => state.imStocks.imStocks;
export const selectIMStockById = (state) => state.imStocks.imStockById;
export const selectIMStockItemsById = (state) => state.imStocks.imStockItemsById;
export const selectIMStockMovementsById = (state) => state.imStocks.imStockMovementsById;
export const selectIMStocksLoading = (state) => state.imStocks.isLoading;
export const selectIMStocksError = (state) => state.imStocks.error;
