import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useLocales from '../../../hooks/useLocales';
import { deleteHRWorkerFeedback } from '../../../redux/slices/hrWorkerFeedbackSlice';

HRWorkerFeedbackDeleteModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  single: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function HRWorkerFeedbackDeleteModal({ passedData, open, handleClose, single }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const handleDelete = () => {
    dispatch(deleteHRWorkerFeedback({ device: window.navigator.userAgent, data: passedData }));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="delete-worker-feedback-dialog">
      <DialogTitle id="delete-worker-feedback-dialog">{translate('deletePopupTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {single ? translate('deletePopupMessageOneItem') : translate('deletePopupMessageMultipleItems')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
