import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    allFuelConsumption: [],
    fuelConsumptionById: [],
    filters: {
        startdate: null,
        enddate: null,
        typeid: null,
    },
    error: '',
};

export const fetchConsumptionFuel = createAsyncThunk(
    'consumptionFuel/fetchConsumptionFuel',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/fuel`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchConsumptionFuelById = createAsyncThunk(
    'consumptionFuel/fetchConsumptionFuelById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/fuel/${id}`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createConsumptionFuel = createAsyncThunk(
    'hrStaff/createConsumptionFuel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/fuel`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateConsumptionFuel = createAsyncThunk(
    'hrStaff/updateConsumptionFuel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/fuel`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteConsumptionFuel = createAsyncThunk(
    'hrStaff/deleteConsumptionFuel',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/fuel`, {
                data
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const consumptionFuelSlice = createSlice({
    name: 'consumptionFuelSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                typeid: null,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchConsumptionFuel.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchConsumptionFuel.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.allFuelConsumption = payload.data;
        });
        builder.addCase(fetchConsumptionFuel.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchConsumptionFuelById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchConsumptionFuelById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.fuelConsumptionById = payload.data;
        });
        builder.addCase(fetchConsumptionFuelById.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createConsumptionFuel.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createConsumptionFuel.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createConsumptionFuel.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateConsumptionFuel.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateConsumptionFuel.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateConsumptionFuel.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteConsumptionFuel.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(deleteConsumptionFuel.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteConsumptionFuel.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const { setFilters, resetFilters } = consumptionFuelSlice.actions;

export default consumptionFuelSlice.reducer;

export const selectConsumptionFuelFilters = (state) => state.consumptionFuel.filters;
export const selectConsumptionFuelData = (state) => state.consumptionFuel.allFuelConsumption;
export const selectConsumptionFuelDataById = (state) => state.consumptionFuel.fuelConsumptionById;
export const selectConsumptionFuelLoading = (state) => state.consumptionFuel.isLoading;
export const selectConsumptionFuelError = (state) => state.consumptionFuel.error;
