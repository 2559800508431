import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  header: [],
  filters: {
    startdate: null,
    enddate: null,
    locationid: '',
    productid: '',
  },
  error: '',
};

export const fetchHarvestMassMovement = createAsyncThunk(
  'harvestMassMovement/fetchHarvestMassMovement',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/harvests/mass-movement`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const harvestMassMovementSlice = createSlice({
  name: 'harvestMassMovementSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
        productid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvestMassMovement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestMassMovement.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data.data;
      state.header = payload.data.header;
    });
    builder.addCase(fetchHarvestMassMovement.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = harvestMassMovementSlice.actions;

export default harvestMassMovementSlice.reducer;

export const selectHarvestMassMovementFilters = (state) => state.harvestMassMovement.filters;
export const selectHarvestMassMovementData = (state) => state.harvestMassMovement.data;
export const selectHarvestMassMovementHeader = (state) => state.harvestMassMovement.header;
export const selectHarvestMassMovementLoading = (state) => state.harvestMassMovement.isLoading;
export const selectHarvestMassMovementError = (state) => state.harvestMassMovement.error;
