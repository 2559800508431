// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
//
import { ModalPopupProvider } from './contexts/ModalContext';
import ModalContainer from './components/ModalContainer';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ModalPopupProvider>
          <NotistackProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
            <ModalContainer />
          </NotistackProvider>
        </ModalPopupProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
