import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    parametersDocumentTypes: [],
    parametersDocumentTypesById: {},
    error: '',
};

export const fetchParametersDocumentTypes = createAsyncThunk(
    'parametersDocumentTypes/fetchParametersDocumentTypes',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/documentType`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersDocumentTypes = createAsyncThunk(
    'parametersDocumentTypes/deleteParametersDocumentTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/documentType`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersDocumentTypes = createAsyncThunk(
    'parametersDocumentTypes/createParametersDocumentTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/documentType`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersDocumentTypesById = createAsyncThunk(
    'parametersDocumentTypes/fetchParametersDocumentTypesById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/documentType/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersDocumentTypes = createAsyncThunk(
    'parametersDocumentTypes/updateParametersDocumentTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/documentType`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersDocumentTypesSlice = createSlice({
    name: 'parametersDocumentTypesSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersDocumentTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersDocumentTypes.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.parametersDocumentTypes = payload.data;
        });
        builder.addCase(fetchParametersDocumentTypes.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersDocumentTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersDocumentTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersDocumentTypes.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersDocumentTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersDocumentTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersDocumentTypes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersDocumentTypesById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.parametersDocumentTypesById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersDocumentTypesById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersDocumentTypesById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersDocumentTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersDocumentTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersDocumentTypes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersDocumentTypesSlice.reducer;

export const selectParametersDocumentTypesData = (state) => state.parametersDocumentTypes.parametersDocumentTypes;
export const selectParametersDocumentTypesById = (state) => state.parametersDocumentTypes.parametersDocumentTypesById;
export const selectParametersDocumentTypesLoading = (state) => state.parametersDocumentTypes.isLoading;
export const selectParametersDocumentTypesError = (state) => state.parametersDocumentTypes.error;
