import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import handleRequestError from '../../utils/handleRequestError';

const initialState = {
    isLoading: true,
    companies: [],
    company: [],
    error: '',
};

export const fetchParametersCompanies = createAsyncThunk(
    'parametersCompanies/fetchParametersCompanies',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/companies`);

            return response.data;
        } catch (err) {
            return handleRequestError(err, rejectWithValue);
        }
    }
);

export const fetchParametersCompany = createAsyncThunk(
    'parametersCompanies/fetchParametersCompany',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/companies/${id}`);

            return response.data;
        } catch (err) {
            return handleRequestError(err, rejectWithValue);
        }
    }
);

export const createParametersCompany = createAsyncThunk(
    'parametersCompanies/createParametersCompany',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/companies`, data);

            return response.data;
        } catch (err) {
            return handleRequestError(err, rejectWithValue);
        }
    }
);

export const updateParametersCompany = createAsyncThunk(
    'parametersCompanies/updateParametersCompany',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/companies`, data);

            return response.data;
        } catch (err) {
            return handleRequestError(err, rejectWithValue);
        }
    }
);

export const deleteParametersCompany = createAsyncThunk(
    'parametersCompanies/deleteParametersCompany',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/companies`, {
                data
            });

            return response.data;
        } catch (err) {
            return handleRequestError(err, rejectWithValue);
        }
    }
);

const parametersCompaniesSlice = createSlice({
    name: 'parametersCompaniesSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersCompanies.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCompanies.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.companies = payload.data;
        });
        builder.addCase(fetchParametersCompanies.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchParametersCompany.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCompany.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.company = payload.data;
        });
        builder.addCase(fetchParametersCompany.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersCompany.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersCompany.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersCompany.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersCompany.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersCompany.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersCompany.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteParametersCompany.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersCompany.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersCompany.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const { setFilters, resetFilters } = parametersCompaniesSlice.actions;

export default parametersCompaniesSlice.reducer;

export const selectParametersCompaniesFilters = (state) => state.parametersCompanies.filters;
export const selectParametersCompaniesData = (state) => state.parametersCompanies.companies;
export const selectParametersCompany = (state) => state.parametersCompanies.company;
export const selectParametersCompaniesLoading = (state) => state.parametersCompanies.isLoading;
export const selectParametersCompaniesError = (state) => state.parametersCompanies.error;
